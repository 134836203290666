import React from "react";

interface ProfilePicProps {
  imageSrc: string;
  altText: string;
  size: "small" | "medium" | "large";
}

const ProfilePic: React.FC<ProfilePicProps> = ({ imageSrc, altText, size }) => {
  const sizeClass = `w-${size} h-${size}`;
  const aspectRatio = "1/1"; // square profile pic

  return (
    <div className={`relative ${sizeClass}`}>
      <div className="inset-0 flex justify-center items-center">
        <img
          className="object-cover"
          style={{
            aspectRatio,
            width: "50%",
            height: "50%",
            // borderRadius: "50%",
            objectFit: "contain",
          }}
          src={imageSrc}
          alt={altText}
        />
      </div>
    </div>
  );
};

export default ProfilePic;
