import React, { useState } from "react";
import { FiArrowDown } from "react-icons/fi";
import { HiOutlineSwitchVertical } from "react-icons/hi";

const DownloadDropdown: React.FC<{
  click?: (str: string) => void;
}> = ({ click }) => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const downloadCSV = () => {
    click("CSV");
  };

  const downloadPDF = () => {
    click("PDF");
  };

  return (
    <div className="relative inline-block text-left flex items-end justify-end w-full">
      <div className="flex items-center">
        {/* <HiOutlineSwitchVertical className="mr-2 h-5 w-5 text-gray-400" /> */}
        <button
          type="button"
          className="inline-flex justify-center items-center w-full rounded-md border-0 bg-white px-0 py-2 text-sm font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 font-semibold"
          onClick={() => setIsOpen(!isOpen)}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : "false"}
        >
          {selectedOption || "Download as"}
          <FiArrowDown
            className={`ml-1 h-5 w-5 text-gray-400 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </button>
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-8 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            <button
              className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900"
              onClick={() => {
                handleOptionChange("Download CSV");
                downloadCSV();
              }}
              role="menuitem"
              tabIndex={0}
              style={{ width: "max-content" }}
            >
              All Records (csv)
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900"
              onClick={() => {
                handleOptionChange("Download PDF");
                downloadPDF();
              }}
              role="menuitem"
              tabIndex={0}
              style={{ width: "max-content" }}
            >
              All Resumes (zip)
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadDropdown;
