import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import DropdownNewSkill from "components/Inputs/DropdownNewSkill";
import DropdownSkillCategory from "components/Inputs/DropdownSkillCategory";
import DropdownWithIcon from "components/Inputs/DropdownWithIcon";
import CertificateUploadWithPreview from "components/Inputs/CertificateUploadWithPreview";
import ToggleInput from "components/Inputs/ToggleInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextInput from "components/Inputs/TextInput";

import {
  AiOutlineProfile,
  AiOutlineBackward,
  AiOutlinePlusCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import { connect } from "react-redux";
import { updateSkillDetailsAction } from "../../store/authSlice";
import ToastComponent from "../../components/Toast/ToastComponent";

interface Employee {
  category: string | null;
  skill_id: string | null;
  relevantexp: string | null;
  competency: string | null;
  skill: string | null;
}

interface Certification {
  id: string;
  name: string;
  number: string | null;
  description: string;
  issue_date: Date;
  expiry_date: Date | null;
  certification_image: File | null;
  certification_url: string | null;
}

const UpdateSkill: React.FC<{
  dispatch: any;
  userDetails: any;
  emplyoeeSkills: any;
  allSkills: any;
  click?: () => void;
  skillobj: any;
}> = ({
  dispatch,
  userDetails,
  emplyoeeSkills,
  allSkills,
  click,
  skillobj,
}) => {
  const [employee, setEmployee] = useState<Employee>({
    category: skillobj.skills.category,
    skill_id: skillobj.skill_id.toString(),
    skill: skillobj.skills.skill,
    relevantexp: skillobj.relevantexp.toString(),
    competency: skillobj.competency.toString(),
  });
  const [skillsList, setSkillsList] = useState([]);
  const [competencyList] = useState([
    {
      label: "1",
      value: { level: "1", rating: "Poor" },
    },
    {
      label: "2",
      value: { level: "2", rating: "Fair" },
    },
    {
      label: "3",
      value: { level: "3", rating: "Average" },
    },
    {
      label: "4",
      value: { level: "4", rating: "Good" },
    },
    {
      label: "5",
      value: { level: "5", rating: "Excellent" },
    },
  ]);
  const [certificationsList, setCertificationsList] = useState<any[]>(
    skillobj.employee_certifications.map((certification: any) => ({
      id: certification.id,
      name: certification.name,
      number: certification.number,
      description: certification.description,
      issue_date: new Date(certification.issue_date),
      expiry_date: certification.expiry_date
        ? new Date(certification.expiry_date)
        : null,
      certification_image: certification.certification_image,
      certification_url: certification.certification_url,
    }))
  );
  const [showCertList, setShowCertList] = useState(
    skillobj.employee_certifications.length > 0
  );

  const [showLoader, setShowLoader] = useState(false);
  const [originalSkillsList, setOriginalSkillsList] = useState([]);
  const [skillsListCategory, setSkillsListCategory] = useState([]);

  const currentDate = new Date();

  useEffect(() => {
    if (allSkills && allSkills.data) {
      const transformedSkills = allSkills.data.map((skill) => ({
        label: skill.skill,
        value: skill.skill_id,
        category: skill.category,
      }));
      setOriginalSkillsList(transformedSkills);

      if (skillobj) {
        let filteredSkills = transformedSkills.filter(
          (item) => item.category === skillobj.skills.category
        );
        setSkillsList(filteredSkills);
      } else {
        setSkillsList(transformedSkills);
      }

      const uniqueCategories = {};
      const skillsByCategory = allSkills.data
        .filter((skill) => {
          if (!uniqueCategories[skill.category]) {
            uniqueCategories[skill.category] = true;
            return true;
          }
          return false;
        })
        .map((skill) => ({
          label: skill.category,
          value: skill.skill_id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setSkillsListCategory(skillsByCategory);
    }
  }, [allSkills, skillobj]);

  const handleChange = (event: any, key: string) => {
    setEmployee({
      ...employee,
      [key]: event.value || event.target.value,
    });
  };

  const handleToggleChange = (isChecked: boolean) => {
    setShowCertList(isChecked);
    if (isChecked === true) {
      setCertificationsList([
        {
          id: "",
          name: "",
          number: null,
          description: "",
          issue_date: new Date(),
          expiry_date: new Date(),
          certification_image: null,
        },
      ]);
    } else {
      setCertificationsList([]);
    }
  };

  const handleSkillsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    fieldName: any
  ) => {
    const { value } = event.target;
    const newList = [...certificationsList];

    newList[index][fieldName] = value;

    setCertificationsList(newList);
  };

  const handleSkillsDateChange = (date: any, index: number, fieldName: any) => {
    const newList = [...certificationsList];
    newList[index][fieldName] = date;

    setCertificationsList(newList);
  };

  const handleCertificateChange = (
    file: File,
    index: number,
    fieldName: string
  ) => {
    const newList = [...certificationsList];
    const newCertification = { ...newList[index] };

    if (fieldName === "certification_image") {
      newCertification.certification_image = file;
    }
    newList[index] = newCertification;
    // console.log('This is :', newList[index])

    setCertificationsList(newList);
  };

  function addMoreSkills() {
    setCertificationsList([
      ...certificationsList,
      {
        id: "",
        name: "",
        number: null,
        description: "",
        issue_date: new Date(),
        expiry_date: new Date(),
        certification_image: null,
      },
    ]);
  }

  function deleteSkill(index: number) {
    const newList = [...certificationsList];
    newList.splice(index, 1);
    setCertificationsList(newList);
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // const handleSubmit = () => {
  //   setShowLoader(true);
  //   let formData = new FormData();

  //   // Append employee data fields from the state
  //   formData.append("skill_id", employee.skill_id);
  //   formData.append("employee_id", userDetails.data.employee.employee_id);
  //   formData.append("relevantexp", employee.relevantexp);
  //   formData.append("competency", employee.competency);
  //   formData.append("is_certificate", `${showCertList === true ? 1 : 0}`);

  //   if (employee.skill_id === null || employee.skill_id === "") {
  //     ToastComponent.error("All * fields are required");
  //     setShowLoader(false);
  //     return;
  //   }
  //   if (
  //     employee.category === "" ||
  //     employee.relevantexp === "" ||
  //     employee.competency === ""
  //   ) {
  //     ToastComponent.error("All * fields are required");
  //     setShowLoader(false);
  //     return;
  //   }

  //   let list = [...certificationsList];

  //   list.forEach((certification, index) => {
  //     formData.append(`certificates[${index}][name]`, certification.name);
  //     formData.append(`certificates[${index}][id]`, certification.id);
  //     formData.append(`certificates[${index}][number]`, certification.number);
  //     formData.append(
  //       `certificates[${index}][description]`,
  //       certification.description
  //     );
  //     formData.append(
  //       `certificates[${index}][issue_date]`,
  //       formatDate(new Date(certification.issue_date))
  //     );
  //     formData.append(
  //       `certificates[${index}][expiry_date]`,
  //       certification.expiry_date
  //         ? formatDate(new Date(certification.expiry_date))
  //         : ""
  //     );
  //     formData.append(
  //       `certificates[${index}][certification_image]`,
  //       certification.certification_image
  //     );
  //   });

  //   dispatch(
  //     updateSkillDetailsAction({ skillId: skillobj.id, params: formData })
  //   )
  //     .then((response: any) => {
  //       setShowLoader(false);
  //       if (
  //         response.payload &&
  //         response.payload.message !== "Validation Error"
  //       ) {
  //         ToastComponent.success("Update Successfully");
  //         setTimeout(() => {
  //           click();
  //         }, 1000);
  //       } else {
  //         ToastComponent.error("All * fields are required");
  //       }
  //     })
  //     .catch((error: any) => {
  //       setShowLoader(false);
  //       console.error("Update Employee Skils Failed", error);
  //       ToastComponent.error("Update Failed");
  //     });
  // };

  const renderErrorsDiv = (data) => {
    return (
      <div>
        {Object.keys(data).map((key) => (
          <div key={key}>
            {data[key].map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const handleSubmit = () => {
    setShowLoader(true);
    let formData = new FormData();

    // Append employee data fields from the state
    formData.append("skill_id", employee.skill_id);
    formData.append("employee_id", userDetails.data.employee.employee_id);
    formData.append("relevantexp", employee.relevantexp);
    formData.append("competency", employee.competency);
    formData.append("is_certificate", `${showCertList === true ? 1 : 0}`);

    if (employee.skill_id === "" || employee.skill_id === null) {
      ToastComponent.error("Skill required");
      setShowLoader(false);
      return;
    }
    if (employee.relevantexp === "") {
      ToastComponent.error("Relevant Experince required");
      setShowLoader(false);
      return;
    }
    if (employee.competency === "" || employee.competency === "select") {
      ToastComponent.error("Competancy required");
      setShowLoader(false);
      return;
    }

    let list = [...certificationsList];

    // Validate each new certification
    for (let i = 0; i < list.length; i++) {
      const certification = list[i];
      if (certification.name === "" || certification.name === null) {
        ToastComponent.error(
          "Certification Name required for all certifications"
        );
        setShowLoader(false);
        return;
      }
      if (
        certification.certification_image === "" ||
        certification.certification_image === null
      ) {
        ToastComponent.error(
          "Certification Image required for all certifications"
        );
        setShowLoader(false);
        return;
      }
    }

    // Append certifications to formData
    list.forEach((certification, index) => {
      formData.append(`certificates[${index}][name]`, certification.name);
      formData.append(`certificates[${index}][id]`, certification.id);
      formData.append(`certificates[${index}][number]`, certification.number);
      formData.append(
        `certificates[${index}][description]`,
        certification.description
      );
      formData.append(
        `certificates[${index}][issue_date]`,
        formatDate(new Date(certification.issue_date))
      );
      formData.append(
        `certificates[${index}][expiry_date]`,
        certification.expiry_date
          ? formatDate(new Date(certification.expiry_date))
          : ""
      );
      formData.append(
        `certificates[${index}][certification_image]`,
        certification.certification_image
      );
    });

    dispatch(
      updateSkillDetailsAction({ skillId: skillobj.id, params: formData })
    )
      .then((response: any) => {
        setShowLoader(false);
        if (response.payload.status === "error") {
          if (response.payload.data) {
            ToastComponent.error(renderErrorsDiv(response.payload.data));
          } else {
            ToastComponent.error("Request Failed!");
          }
        }
        if (
          response.payload &&
          response.payload.message !== "Validation Error"
        ) {
          ToastComponent.success("Update Successfully");
          setTimeout(() => {
            click();
          }, 1000);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
        console.error("Update Employee Skils Failed", error);
        ToastComponent.error("Update Failed");
      });
  };

  const selectCategory = (event) => {
    const label = event.label;
    setEmployee({
      ...employee,
      category: label,
    });
    let filteredItems = originalSkillsList.filter(
      (item) => item.category === label
    );
    filteredItems.sort((a, b) => a.label.localeCompare(b.label));
    setSkillsList(filteredItems);
    setTimeout(() => {
      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        skill: ".",
        skill_id: null,
      }));
      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        skill: "",
        skill_id: null,
      }));
    }, 500);
  };

  return (
    <>
      <div className="ml-auto h-screen mb-2">
        <div className="flex flex-row items-center mx-auto w-full">
          <AiOutlineBackward
            className="mr-2 cursor-pointer"
            onClick={() => click()}
          />
          <PageTitle
            title={"UPDATE SKILL"}
            preTitleIcon={<AiOutlineProfile fontSize={20} />}
          />
        </div>
        <div className="flex flex-col w-full ml-2 mt-8">
          <div style={{ width: "51%" }}>
            <DropdownSkillCategory
              title="Skill Category *"
              id="skillCategory"
              name="skillCategory"
              value={employee.category}
              onChange={selectCategory}
              options={skillsListCategory}
            />
          </div>
          <div style={{ width: "51%" }}>
            <DropdownNewSkill
              label="Select Skill *"
              id="skill_id"
              name="skill_id"
              value={employee.skill}
              onChange={(e) => {
                handleChange(e, "skill_id");
              }}
              options={skillsList}
            />
          </div>
          <div className="flex flex-row">
            <div style={{ width: "24%" }}>
              <TextInput
                label="Relevant Experince (In years) *"
                id={`RelevantExp`}
                name={"relevantexp"}
                value={employee.relevantexp}
                onChange={(e) => {
                  handleChange(e, "relevantexp");
                }}
                placeHolder="Enter Relevant Experince"
              />
            </div>
            <div style={{ width: "24%", marginLeft: "2rem" }}>
              <DropdownWithIcon
                label="Competancy *"
                id="competency"
                name="competency"
                value={employee.competency}
                onChange={(e) => {
                  handleChange(e, "competency");
                }}
                options={competencyList}
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <ToggleInput
            onChange={handleToggleChange}
            title="Add New Certifications"
            initialValue={showCertList}
          />
        </div>
        {showCertList === true &&
          certificationsList &&
          certificationsList.map((certification, index) => (
            <div key={index} className="mt-12 ml-2">
              <div className="flex">
                <div style={{ width: "25%" }}>
                  <TextInput
                    label="Certification Name *"
                    id={`certification_name_${index}`}
                    name={`name_${index}`}
                    value={certification.name}
                    onChange={(event) =>
                      handleSkillsChange(event, index, "name")
                    }
                    placeHolder="Enter Certification Name"
                  />
                </div>
                <div className="ml-2" style={{ width: "25%" }}>
                  <TextInput
                    label="Certification Number"
                    id={`certification_number_${index}`}
                    name={`number_${index}`}
                    value={certification.number}
                    onChange={(event) =>
                      handleSkillsChange(event, index, "number")
                    }
                    placeHolder="Enter Certification Number"
                  />
                </div>
                <div className="ml-2" style={{ width: "25%" }}>
                  <TextInput
                    label="Certificate Description"
                    id={`certificate_description_${index}`}
                    name={`description_${index}`}
                    value={certification.description}
                    onChange={(event) =>
                      handleSkillsChange(event, index, "description")
                    }
                    placeHolder="Enter Certificate Description"
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: "25%" }} className="certificatePicker">
                  <p>Enter Certificate Issue Date</p>
                  <DatePicker
                    id={`certificate_issue_date_${index}`}
                    selected={certification.issue_date}
                    onChange={(date: any) =>
                      handleSkillsDateChange(date, index, "issue_date")
                    }
                    dateFormat="yyyy-MM-dd"
                    maxDate={currentDate}
                    className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div
                  className="ml-2 certificatePicker"
                  style={{ width: "25%" }}
                >
                  <p>Enter Certificate Expiry Date</p>
                  <DatePicker
                    id={`certificate_expiry_date_${index}`}
                    selected={certification.expiry_date}
                    onChange={(date: any) =>
                      handleSkillsDateChange(date, index, "expiry_date")
                    }
                    dateFormat="yyyy-MM-dd"
                    // maxDate={currentDate}
                    className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="ml-2" style={{ width: "25%" }}>
                  <CertificateUploadWithPreview
                    label="Certificate (Image, pdf only) *"
                    id={`Certificate-upload-${index}`}
                    onChange={(file, index) =>
                      handleCertificateChange(
                        file,
                        index,
                        "certification_image"
                      )
                    }
                    index={index}
                    initialFileUrl={certification.certification_url}
                  />
                </div>
              </div>
              {certificationsList.length > 1 && (
                <div
                  className="flex items-center justify-start cursor-pointer inline-flex"
                  onClick={() => deleteSkill(index)}
                >
                  <AiOutlineDelete size={"15px"} />{" "}
                  <span className="ml-1">Delete</span>
                </div>
              )}
            </div>
          ))}
        {showCertList === true && (
          <div
            className="flex items-center justify-start cursor-pointer ml-2 mt-2"
            onClick={() => addMoreSkills()}
          >
            <AiOutlinePlusCircle size={"18px"} />{" "}
            <span className="ml-2 mb-1">Add more</span>
          </div>
        )}

        <div className="flex items-center justify-center mt-8">
          <div style={{ width: "20%" }}>
            <button
              type="submit"
              className={`bg-black text-white rounded px-4 py-2 ${
                showLoader ? "opacity-50 pointer-events-none" : ""
              }`}
              onClick={handleSubmit}
            >
              {showLoader ? "Submitting..." : "Update Skill"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.auth.userDetials,
    emplyoeeSkills: state.auth.emplyoeeSkills,
    allSkills: state.auth.allSkills,
  };
};

export default connect(mapStateToProps)(UpdateSkill);
