import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import {
  LOGIN_URL,
  SAVE_EMPLOYEE_DETAILS,
  UPDATE_EMPLOYEE_DETAILS,
  EMPLOYEE_LOCATIONS,
  GET_USER_DETAILS,
  GET_EMPLOYEE_SKILLS,
  GET_EMPLOYEE_DETAILS,
  GET_EMPLOYEE_SKILLS_WITHFILTER,
  GET_ALLSKILLS,
  GET_ALLSKILLSCATEGORY,
  GET_ALLSKILLS_BYPARAMS,
  GET_MY_SKILLS,
  SAVE_SKILL_DETAILS,
  DELETE_SKILL_DETAILS,
  UPDATE_SKILL_DETAILS,
} from "./constants";

// Helper function to handle HTTP requests
const makeHttpRequest = async <T>(
  method: string,
  url: string,
  data?: any,
  options?: AxiosRequestConfig
): Promise<T> => {
  try {
    // Get token from localStorage
    const token = localStorage.getItem("token");

    // Set default headers with bearer token, excluding for login endpoint
    const headers =
      url === LOGIN_URL
        ? options?.headers
        : {
            Authorization: `Bearer ${token}`,
            ...options?.headers,
          };

    // Make request with updated headers
    const response: AxiosResponse<T> = await axios({
      method,
      url,
      data,
      headers,
      ...options,
    });

    return response.data;
  } catch (error) {
    // Error handling
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const { status, statusText } = axiosError.response;
        if (status === 400) {
          throw new Error("Bad request: " + statusText);
        } else if (status === 401) {
          throw new Error("Unauthorized: " + statusText);
        } else if (status === 404) {
          throw new Error("Not found: " + statusText);
        } else if (status === 500) {
          throw new Error("Internal server error: " + statusText);
        } else {
          throw new Error(`Server error: ${status} - ${statusText}`);
        }
      } else {
        throw new Error("No response from server.");
      }
    } else {
      throw error;
    }
  }
};

//API endpoints
export const userLogin = async (email: string, password: string) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "POST",
      LOGIN_URL,
      { email, password }
    );
    const token = response.token;
    localStorage.setItem("token", token);
    return { token };
  } catch (error) {
    throw error;
  }
};

export const saveEmployeeDetails = async (formData: any) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "POST",
      SAVE_EMPLOYEE_DETAILS,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateEmployeeDetails = async (formData: any, id: number) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "POST",
      UPDATE_EMPLOYEE_DETAILS + `/${id}`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveSkillDetails = async (formData: any) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "POST",
      SAVE_SKILL_DETAILS,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSkillDetails = async (skillId: number) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "DELETE",
      DELETE_SKILL_DETAILS + `/${skillId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSkillDetails = async (skillId: any, formData: any) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "POST",
      UPDATE_SKILL_DETAILS + `/${skillId}`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const userLocations = async () => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      EMPLOYEE_LOCATIONS
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = async () => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_USER_DETAILS
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeSkills = async () => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_EMPLOYEE_SKILLS
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeDetails = async (user: any) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_EMPLOYEE_DETAILS + `/${user.employee_id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchEmployeeSpecificSkills = async (user: any) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "POST",
      GET_EMPLOYEE_SKILLS_WITHFILTER + `/${user.employee_id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchEmployeeAllSkills = async () => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_ALLSKILLS
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const fetchEmployeeAllSkillsCategory = async () => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_ALLSKILLSCATEGORY
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAllSkillsByparams = async (paramsURL: any) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_ALLSKILLS_BYPARAMS + paramsURL
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchMySkills = async (user: any) => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_MY_SKILLS + `/${user.employee_id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchMySkillsRecords = async () => {
  try {
    const response = await makeHttpRequest<{ token: string }>(
      "GET",
      GET_ALLSKILLS_BYPARAMS
    );
    return response;
  } catch (error) {
    throw error;
  }
};
