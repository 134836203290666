import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import TextInput from "components/Inputs/TextInput";
import InputDropdown from "components/Inputs/Dropdown";
import ImageUploadWithPreview from "components/Inputs/ImageUploadWithPreview";
import FileUploadWithPreview from "components/Inputs/FileUploadWithPreview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiFillBackward } from "react-icons/ai";
import { connect } from "react-redux";
import ToastComponent from "../../components/Toast/ToastComponent";
import { updateEmployeeDetailsAction } from "../../store/authSlice";

interface Employee {
  employee_firstname: string;
  employee_lastname: string;
  employee_code: string;
  employement_type: string;
  relevantexp: string;
  totalexp: string;
  location: string;
  startdate: Date;
  enddate: Date;
  isactive: boolean;
  employee_image?: any;
  resumelink?: any;
  employee_id: number;
}

interface Location {
  id: number;
  city: string;
  created_at: string;
  updated_at: string;
}

interface LocationsResponse {
  status: string;
  message: string;
  data: Location[];
}

const UpdateProfile: React.FC<{
  locations: LocationsResponse | null;
  dispatch: any;
  emplyoeeDetails?: any;
  redirectClick?: () => void;
}> = ({ dispatch, locations, emplyoeeDetails, redirectClick }) => {
  const [employee, setEmployee] = useState<Employee>({
    employee_firstname: "",
    employee_lastname: "",
    employee_code: "",
    employement_type: "",
    relevantexp: "",
    totalexp: "",
    location: "",
    startdate: new Date(),
    enddate: new Date(),
    isactive: true,
    employee_id: 0,
    employee_image: "",
    resumelink: "",
  });

  const [locationOptions, setLocationOptions] = useState([]);
  const [emplyoeeTypeOptions, setEmplyoeeTypeOptions] = useState([
    { label: "Contractor", value: "contractor" },
    { label: "Full-time", value: "fulltime" },
  ]);

  // const isActivedropdownOptions = [
  //   { label: "Yes", value: "1" },
  //   { label: "No", value: "0" },
  // ];

  const [imageFile, setImageFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (locations && locations.data && locations.data.length > 0) {
      const transformedLocations = locations.data.map((location: Location) => ({
        label: location.city,
        value: location.city,
      }));
      setLocationOptions(transformedLocations);
    }
  }, [locations]);

  useEffect(() => {
    console.log("emplyoeeDetails", emplyoeeDetails);
    if (emplyoeeDetails && emplyoeeDetails.data) {
      const data = { ...emplyoeeDetails.data[0] };
      if (data.startdate) {
        data.startdate = new Date(data.startdate);
      } else {
        data.startdate = "";
      }

      if (data.enddate) {
        data.enddate = new Date(data.enddate);
      } else {
        data.enddate = "";
      }
      setEmployee((prevState) => ({
        ...prevState,
        employee_firstname: data.employee_firstname,
        employee_lastname: data.employee_lastname,
        employee_code: data.employee_code,
        employement_type: data.employement_type,
        relevantexp: data.relevantexp,
        totalexp: data.totalexp,
        location: data.location,
        startdate: data.startdate,
        enddate: data.startdate,
        isactive: true,
        employee_id: data.employee_id,
        employee_image: data.employee_image,
        resumelink: data.resumelink,
      }));
    }
  }, [emplyoeeDetails]);

  useEffect(() => {
    console.log("employee", employee);
  }, [employee]);

  const handleImageChange = (file: any) => {
    setImageFile(file);
  };

  const handleResumeChange = (file: any) => {
    setResumeFile(file);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (
      event.target.name === "employee_firstname" ||
      event.target.name === "employee_lastname"
    ) {
      let regex = /^[a-zA-Z\s]+$/;
      if (event.target.value === "") {
        setEmployee({
          ...employee,
          [event.target.name]: event.target.value,
        });
      }
      if (regex.test(event.target.value)) {
        setEmployee({
          ...employee,
          [event.target.name]: event.target.value,
        });
      }
    } else {
      setEmployee({
        ...employee,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleDateChange = (date: Date, key: keyof Employee) => {
    setEmployee({
      ...employee,
      [key]: date,
    });
  };

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const renderErrorsDiv = (data) => {
    return (
      <div>
        {Object.keys(data).map((key) => (
          <div key={key}>
            {data[key].map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        ))}
      </div>
    );
  };
  const handleSubmit = () => {
    setShowLoader(true);
    let formData = new FormData();

    if (employee.employee_firstname === "") {
      ToastComponent.error("First name required");
      setShowLoader(false);
      return;
    }

    if (employee.employee_lastname === "") {
      ToastComponent.error("Last name required");
      setShowLoader(false);
      return;
    }

    if (employee.employee_code === "") {
      ToastComponent.error("Employee code required");
      setShowLoader(false);
      return;
    }

    if (employee.employement_type === "") {
      ToastComponent.error("Employement Type required");
      setShowLoader(false);
      return;
    }

    if (employee.relevantexp === "") {
      ToastComponent.error("Relevant Experience required");
      setShowLoader(false);
      return;
    }

    if (employee.totalexp === "") {
      ToastComponent.error("Total Experience required");
      setShowLoader(false);
      return;
    }

    if (employee.location === "") {
      ToastComponent.error("Location required");
      setShowLoader(false);
      return;
    }

    if (employee.employee_image === "" || employee.employee_image === null) {
      ToastComponent.error("Image required");
      setShowLoader(false);
      return;
    }

    if (employee.resumelink === "" || employee.resumelink === null) {
      ToastComponent.error("Resume required");
      setShowLoader(false);
      return;
    }
    if (employee.startdate === null) {
      ToastComponent.error("Start Date required");
      setShowLoader(false);
      return;
    }

    // Append employee data fields from the state
    formData.append("employee_firstname", employee.employee_firstname);
    formData.append("employee_lastname", employee.employee_lastname);
    formData.append("employee_code", employee.employee_code);
    if (employee.employement_type === "select") {
      ToastComponent.error("Select Employee Type");
      setShowLoader(false);
      return;
    }
    if (employee.relevantexp > employee.totalexp) {
      ToastComponent.error(
        "Relevant Experince should be less than Total Experince"
      );
      setShowLoader(false);
      return;
    }
    formData.append("employement_type", employee.employement_type);
    formData.append("relevantexp", employee.relevantexp);
    formData.append("totalexp", employee.totalexp);
    if (employee.location === "select") {
      ToastComponent.error("Select Location");
      setShowLoader(false);
      return;
    }
    formData.append("location", employee.location);
    formData.append("startdate", formatDate(employee.startdate));
    if (employee.enddate) {
      formData.append("enddate", formatDate(employee.enddate));
    } else {
      formData.append("enddate", "");
    }
    formData.append("isactive", "1");

    // Append the image file if available
    if (imageFile || employee.employee_image) {
      formData.append("employee_image", imageFile || employee.employee_image);
    }

    // Append the resume file if available
    if (resumeFile || employee.resumelink) {
      formData.append("resumelink", resumeFile || employee.resumelink);
    }
    dispatch(
      updateEmployeeDetailsAction({
        params: formData,
        id: employee.employee_id,
      })
    )
      .then((response: any) => {
        setShowLoader(false);
        if (response.payload.status === "error") {
          if (response.payload.data) {
            ToastComponent.error(renderErrorsDiv(response.payload.data));
          } else {
            ToastComponent.error("Request Failed!");
          }
        }
        if (
          response.payload &&
          response.payload.message !== "Validation Error"
        ) {
          ToastComponent.success("Updated Successfully");
          setTimeout(() => {
            redirectClick();
          }, 1000);
        }
      })
      .catch((error: any) => {
        setShowLoader(false);
        console.error("Update Employee Details Failed", error);
        ToastComponent.error("Saving Failed");
      });
  };

  // const renderResumeName = (url: any) => {
  //   if (url) {
  //     try {
  //       const urlParts = url.split("/");
  //       const filename = urlParts[urlParts.length - 1];
  //       const parts = filename.split("_");
  //       const fullName = parts[1];
  //       const firstName = fullName.split(" ")[0];
  //       const lastPart = parts[2];
  //       const newName = `${firstName}${lastPart}`;
  //       return newName;
  //     } catch (error) {
  //       console.error("An error occurred:", error);
  //       return "";
  //     }
  //   }
  // };

  const renderResumeName = (url: any) => {
    if (url) {
      try {
        const urlParts = url.split("/");
        const filename = urlParts[urlParts.length - 1];
        const parts = filename.split("_");
        return parts.slice(1).join("_") || filename;
      } catch (error) {
        console.error("An error occurred:", error);
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <div className="ml-auto h-screen">
      <div className="mx-auto w-full">
        <PageTitle
          title={"UPDATE PROFILE"}
          preTitleIcon={
            <AiFillBackward
              fontSize={20}
              onClick={() => redirectClick()}
              style={{ cursor: "pointer" }}
            />
          }
        />
        <div className="flex emp-form-grid">
          <div className="md\:w-4\/12">
            <TextInput
              label={"First Name *"}
              id="employee_firstname"
              name="employee_firstname"
              value={employee.employee_firstname}
              onChange={handleChange}
              placeHolder="Enter First Name"
            />
            {/* <TextInput
              label={"Employee Type *"}
              id="employement_type"
              name="employement_type"
              value={employee.employement_type}
              onChange={handleChange}
              placeHolder="Enter Employee Type"
            /> */}
            <div className="mt-4">
              <InputDropdown
                label="Emplyoee Type *"
                id="employement_type"
                name="employement_type"
                value={employee.employement_type}
                onChange={handleChange}
                options={emplyoeeTypeOptions}
              />
            </div>
            <TextInput
              label={"Total Experience *"}
              id="totalexp"
              name="totalexp"
              value={employee.totalexp}
              onChange={handleChange}
              placeHolder="Enter Total Experience"
            />
            <div className="TextInput emplyoee-DatePicker">
              <label
                htmlFor="startdate"
                className="block text-sm font-semibold text-gray-700"
              >
                {"Employment Start Date *"}
              </label>
              <DatePicker
                id="startdate"
                selected={employee.startdate}
                onChange={(date: any) =>
                  handleDateChange(date as Date, "startdate")
                }
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                // Optional: Custom styling for disabled dates (if desired)
                disabledClassName="text-gray-500 cursor-not-allowed"
                className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            {/* <div className="mt-4">
              <InputDropdown
                label="Is Active *"
                id="isactive"
                name="isactive"
                value={employee.isactive}
                onChange={handleChange}
                options={isActivedropdownOptions}
              />
            </div> */}
          </div>
          <div className="md\:w-4\/12">
            <TextInput
              label={"Last Name *"}
              id="employee_lastname"
              name="employee_lastname"
              value={employee.employee_lastname}
              onChange={handleChange}
              placeHolder="Enter Last Name"
            />
            <TextInput
              label={"Employee Code *"}
              id="employee_code"
              name="employee_code"
              value={employee.employee_code}
              onChange={handleChange}
              placeHolder="Enter Employee Code"
            />
            <TextInput
              label={"Relevant Experince *"}
              id="relevantexp"
              name="relevantexp"
              value={employee.relevantexp}
              onChange={handleChange}
              placeHolder="Enter Relevant Experience"
            />
            {/* <div className="TextInput emplyoee-DatePicker">
              <label
                htmlFor="enddate"
                className="block text-sm font-semibold text-gray-700"
              >
                {"Employment End Date"}
              </label>
              <DatePicker
                id="enddate"
                selected={employee.enddate}
                onChange={(date: any) =>
                  handleDateChange(date as Date, "enddate")
                }
                dateFormat="yyyy-MM-dd"
                className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div> */}
            <div className="mt-4">
              <InputDropdown
                label="Current Location *"
                id="location"
                name="location"
                value={employee.location}
                onChange={handleChange}
                options={locationOptions}
              />
            </div>
          </div>
          <div className="md\:w-4\/12">
            <div>
              <ImageUploadWithPreview
                label="Profile Picture (JPEG/JPG/PNG)*"
                id="image-upload"
                onChange={handleImageChange}
                width={"250px"}
                height={"170px"}
                initialImageUrl={employee.employee_image}
              />
            </div>
            <div className="mt-12">
              <FileUploadWithPreview
                label="Resume (PDF / Word) *"
                id="Resume-upload"
                onChange={handleResumeChange}
                initialFileUrl={renderResumeName(employee.resumelink)}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-6">
          <button
            type="submit"
            className={`bg-black text-white rounded px-4 py-2 ${
              showLoader ? "opacity-50 pointer-events-none" : ""
            }`}
            onClick={handleSubmit}
          >
            {showLoader ? "Submitting..." : "Save Details"}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    locations: state.auth.locations,
    emplyoeeDetails: state.auth.emplyoeeDetails,
  };
};

export default connect(mapStateToProps)(UpdateProfile);
