import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ClientData {
  client: string;
  max: number;
  min: number;
  avg: number;
}

const chartData: ClientData[] = [
  { client: "ABC", max: 5, min: 2, avg: 2 },
  { client: "DEF", max: 8, min: 5, avg: 8 },
  { client: "HIJ", max: 5, min: 5, avg: 5 },
  { client: "KLM", max: 10, min: 5, avg: 10 },
];

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Skill wise competency",
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // Disable x-axis grid lines
      },
      stacked: true,
    },
    y: {
      grid: {
        display: false, // Disable x-axis grid lines
      },
      stacked: true,
      ticks: {
        callback: (value: any, index: number) => {
          if (index % 2 === 0) {
            // Display years text on every alternate tick
            return value + " years";
          } else {
            return ""; // Hide years text on other ticks
          }
        },
      },
    },
  },
};

const barChartData = {
  labels: chartData.map((item) => item.client),
  datasets: [
    {
      label: "Data",
      data: chartData.map((item) => item.avg),
      backgroundColor: chartData.map((item, index) =>
        index % 2 === 0 ? "rgba(255, 0, 0, 0.5)" : "rgba(211, 155, 155, 1)"
      ), // Alternating colors
      barThickness: 20, // Set the bar width here
    },
  ],
};

function TopClientEngagement() {
  return (
    <div className="container mx-auto border-2 shadow-sm rounded p-4">
      <h1 className="text-xl font-bold mb-4">Top Client Engagement</h1>
      <Bar options={options} data={barChartData} />
    </div>
  );
}

export default TopClientEngagement;
