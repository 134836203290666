import React from "react";

interface ButtonProps {
  backgroundColor: string;
  icon?: React.ReactNode;
  onClick: () => void;
  text: string;
}

const Button: React.FC<ButtonProps> = ({
  backgroundColor,
  icon,
  onClick,
  text,
}) => {
  return (
    <button
      className={`flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-${backgroundColor} focus:outline-none rounded text-lg w-full`}
      onClick={onClick}
    >
      {text}
      {icon && <span className="ml-2">{icon}</span>}
    </button>
  );
};

export default Button;
