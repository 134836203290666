import React, { useState, useEffect } from "react";
import ToastComponent from "../../components/Toast/ToastComponent";

interface Props {
  label: string;
  id: string;
  onChange: (file: File | null) => void;
  initialFileUrl?: string;
}

function FileUploadWithPreview({ label, id, onChange, initialFileUrl }: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState(initialFileUrl);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      if (validateFileType(file)) {
        setSelectedFile(file);
        setFileUrl(URL.createObjectURL(file));
        if (onChange) {
          onChange(file);
        }
      } else {
        setSelectedFile(null);
        setFileUrl("");
        ToastComponent.error("Please choose a PDF or Word document");
      }
    }
  };


  

  const validateFileType = (file: File): boolean => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    return allowedTypes.includes(file.type);
  };

  const handleClick = () => {
    // Programmatically click the hidden file input
    const fileInput = document.getElementById(id) as HTMLInputElement;
    fileInput && fileInput.click();
  };



const handleRemove = () => {
  if (initialFileUrl) {
    setSelectedFile(null);
    setFileUrl(initialFileUrl);
    if (onChange) {
      onChange(null);
    }
  } else {
    setSelectedFile(null);
    setFileUrl("");
    if (onChange) {
      onChange(null);
    }
  }
};

  useEffect(() => {
    if (initialFileUrl) {
      setFileUrl(initialFileUrl);
    }
  }, [initialFileUrl]);

  return (
    <div className="mb-4">
      <label htmlFor={id} className="block text-sm font-semibold text-gray-700">
        {label}
      </label>
      <div
        className="mt-1 flex items-center border-2"
        style={{
          width: "250px",
          height: "70px",
          background: "#f8f9f5",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <div className="flex-grow mr-2 flex items-center justify-center">
           {selectedFile || fileUrl ? (
            <div className="flex flex-col items-center justify-between w-full">
              <span className="text-sm font-medium text-gray-700">
                {selectedFile
                  ? selectedFile.name
                  : initialFileUrl
                  ? initialFileUrl.split("/").pop()
                  : ""}
              </span>
              <button
                className="text-red-600 hover:text-red-800 focus:outline-none mt-2"
                onClick={handleRemove}
              >
                Remove
              </button>
            </div>
          ) : (
            <span className="text-sm font-medium text-gray-700">
              Choose a file
            </span>
          )} 



          <input
            type="file"
            id={id}
            onChange={handleFileChange}
            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            className="sr-only"
          />
        </div>
      </div>
    </div>
  );
}

export default FileUploadWithPreview;



