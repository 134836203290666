import React, { useEffect } from "react";
import { RxDashboard } from "react-icons/rx";
import { connect } from "react-redux";
import { IoInformationCircle } from "react-icons/io5";
import PageTitle from "components/PageTitle/PageTitle";
import SearchInput from "components/UserInputs/SearchInput";
import ClientEngAvgChart from "components/Charts/ClientEngAvgChart";
import SkillWiseChart from "components/Charts/SkillWiseChart";
import TopClientEngagement from "components/Charts/TopClientEngagement";
import ClientEngagementsTable from "components/Tables/ClientEngagementsTable";
import DownloadDropdown from "components/Dropdowns/DownloadData";
import {
  getUserDetailsAction,
  fetchEmployeeDetails,
} from "../../store/authSlice";

import "../../assets/styles/common.css";
import { Redirect } from "react-router";

const DashboardPage: React.FC<{ dispatch: any; userDetails: any }> = ({
  dispatch,
  userDetails,
}) => {
  useEffect(() => {
    if (dispatch) {
      dispatch(getUserDetailsAction());
    }
  }, [dispatch]);

  useEffect(() => {
    if (userDetails && userDetails.data) {
      if (userDetails.data.employee && Object.keys(userDetails.data.employee).length > 0) {
        dispatch(fetchEmployeeDetails({ user: userDetails.data.employee }))
          .then((response: any) => {
            console.log(response);
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    }
  }, [dispatch, userDetails]);

  return (
    <>
      <Redirect to="/employee-details" />
      <div className="mt-20 py-4 px-8 w-3/4 ml-auto h-screen">
        <div className="px-4 md:px-10 mx-auto w-full">
          <PageTitle
            title={"Dashboard"}
            preTitleIcon={<RxDashboard fontSize={20} />}
            postTitleIcon={<IoInformationCircle fontSize={20} />}
            click={(a) => console.log(a)}
          />
          <SearchInput />
        </div>
        <div className="px-4 md:px-10 mx-auto w-full">
          <DownloadDropdown />
        </div>
        <div className="px-4 md:px-10 mx-auto w-full mt-4">
          <div className="flex justify-between flex-col md:flex-row">
            <div className="lg:w-6/12 xl:w-6/12 mr-2">
              <ClientEngAvgChart />
            </div>
            <div className="lg:w-6/12 xl:w-6/12 ml-2 md:ml-0 mt-4 md:mt-0">
              <ClientEngagementsTable />
            </div>
          </div>
        </div>

        <div className="px-4 md:px-10 mx-auto w-full mt-10">
          <div className="flex items-center justify-center">
            <h1 className="text-xl font-bold mr-4">Summary</h1>
            <div className="mt-2 w-full dashboard-summary-bar"></div>
          </div>
        </div>
        <div className="px-4 md:px-10 mx-auto w-full mt-10">
          <div className="flex justify-between flex-col md:flex-row">
            <div className="lg:w-6/12 xl:w-6/12 mr-2 mb-8">
              <SkillWiseChart />
            </div>
            <div className="lg:w-6/12 xl:w-6/12 ml-2 md:ml-0 mt-4 md:mt-0 mb-8">
              <TopClientEngagement />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.auth.userDetials,
  };
};

export default connect(mapStateToProps)(DashboardPage);
