import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Logo_dark from "../../assets/img/logo-dark.png";

import UserDropdown from "components/Dropdowns/UserDropdown";

interface AdminNavBarProps {
  onLogout: () => void;
  isLoggedIn: boolean;
  emplyoeeDetails?: any;
}

const Navbar: React.FC<AdminNavBarProps> = ({
  emplyoeeDetails,
  isLoggedIn,
  onLogout,
}) => {
  const [urlImg, setUrlImg] = useState("");

  useEffect(() => {
    if (emplyoeeDetails && emplyoeeDetails.data && emplyoeeDetails.data[0]) {
      setUrlImg(emplyoeeDetails.data[0].employee_image);
    }
  }, [emplyoeeDetails]);

  return (
    <>
      <nav className="bg-white py-2 md:fixed top-0 left-0 w-full z-10 border-2">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-2 px-0"
            to="/"
          >
            <img src={Logo_dark} alt="Logo_dark" width={100} />
          </Link>
          {isLoggedIn ? (
            <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
              <UserDropdown onLogout={onLogout} urlImg={urlImg} />
            </ul>
          ) : null}
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    emplyoeeDetails: state.auth.emplyoeeDetails,
  };
};

export default connect(mapStateToProps)(Navbar);
