import React, { useEffect, useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import ProfilePic from "components/Profile/ProfilePic";
import { AiOutlineProfile } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { IoIosAttach } from "react-icons/io";
import { connect } from "react-redux";

import Button from "components/Buttons/Button";

interface User {
  employee_id: number;
  user_id: number;
  employee_code: string;
  employee_firstname: string;
  employee_middlename: string | null;
  employee_lastname: string;
  resumelink: string | null;
  employement_type: string;
  startdate: string;
  enddate: null;
  location: string;
  totalexp: number;
  relevantexp: number;
  isactive: number;
  created_at: string;
  updated_at: "2024-03-07T10:51:24.000000Z";
  employee_image: string;
  image_master: null;
}

const MyProfile: React.FC<{
  userDetails: any;
  mySkills: any;
  emplyoeeDetails: any;
  click?: () => void;
}> = ({ userDetails, mySkills, emplyoeeDetails, click }) => {
  const [empInfo, setEmpInfo] = useState<User | null>(null);

  useEffect(() => {
    if (emplyoeeDetails && emplyoeeDetails.data) {
      setEmpInfo(emplyoeeDetails.data[0]);
    }
  }, [emplyoeeDetails]);

  // const renderResumeName = (url: any) => {
  //   if (url) {
  //     try {
  //       const urlParts = url.split("/");
  //       const filename = urlParts[urlParts.length - 1];
  //       const parts = filename.split("_");
  //       const fullName = parts[1];
  //       const firstName = fullName.split(" ")[0];
  //       const lastPart = parts[2];
  //       const newName = `${firstName} ...${lastPart}`;
  //       return newName;
  //     } catch (error) {
  //       console.error("An error occurred:", error);
  //       return "";
  //     }
  //   }
  // };

  function downloadPDF(url: any) {
    try {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("An error occurred while downloading the PDF:", error);
    }
  }


  const toCamelCase =( str: string )=> {
    const s =
      str &&
      str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
        .join('');
    return s.slice(0, 1).toLowerCase() + s.slice(1);
  };
  


  return (
    <>
      <div className="ml-auto h-screen">
        <div className="mx-auto w-full">
          <PageTitle
            title={"MY PROFILE"}
            preTitleIcon={<AiOutlineProfile fontSize={20} />}
            postTitleIcon={<MdEdit fontSize={20} />}
            click={(a) => {
              if (a === "update Profile") {
                click();
              }
            }}
            clickType="update Profile"
          />
          <div className="flex items-center justify-between emp-profile">
            <div className="border overflow-hidden emp-profile-bg">
              <div className="mt-4 flex flex-col items-center justify-between">
                <h2 className="text-lg font-bold mb-2">
                  {empInfo && empInfo.employee_firstname}{" "}
                  {empInfo && empInfo.employee_lastname}
                </h2>
                <ProfilePic
                  imageSrc={
                    empInfo && empInfo.employee_image
                      ? empInfo.employee_image
                      : "https://placehold.co/400"
                  }
                  altText="Profile pic"
                  size="large"
                />
                <p className="text-sm font-bold mt-4 tracking-wide">
                  {/* {empInfo && empInfo.employement_type} */}
                  {empInfo && empInfo.employement_type.toUpperCase()}
                  {/* {empInfo && toCamelCase(empInfo.employement_type)} */}

                </p>
              </div>
              <div className="mt-4 flex flex-col items-start justify-between">
                <p className="text-sm text-gray-500">
                  Emp ID: {empInfo && empInfo.employee_code}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  Current Location: {empInfo && empInfo.location}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  Relevant Experince: {empInfo && empInfo.relevantexp} years
                </p>
              </div>
              <div className="flex items-center justify-center mt-6">
                <Button
                  backgroundColor="black"
                  icon={<IoIosAttach fontSize={20} />}
                  onClick={() => downloadPDF(empInfo && empInfo.resumelink)}
                  text={"Resume"}
                />
              </div>
            </div>
            <div className="">
              {mySkills && mySkills.data && mySkills.data.length > 0 ? (
                <div>
                  <p className="font-bold emp-info-title">Top Skills</p>
                  <div className="mt-4 emp-info-container">
                    {mySkills.data.slice(0, 5).map((skill: any) => (
                      <div key={skill.skill_id} className="emp-skill">
                        <p className="emp-skill-name px-2 py-1">
                          {skill.skills.skill}
                        </p>
                        <p className="bg-black text-white px-2 py-1 emp-skill-id ml-3">
                         <span>{skill.relevantexp } y</span>
                        </p>

                  
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No skill found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.auth.userDetials,
    mySkills: state.auth.mySkills,
    emplyoeeDetails: state.auth.emplyoeeDetails,
  };
};

export default connect(mapStateToProps)(MyProfile);
