import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const SkillsTable: React.FC<{
  colDefs: any;
  rowData?: any;
}> = ({ colDefs = [], rowData = [] }) => {
  return (
    <div
      className={"ag-theme-quartz"}
      style={{ width: "100%", height: "30rem" }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        domLayout="autoHeight"
        autoSizePadding={20}
      />
    </div>
  );
};

export default SkillsTable;
