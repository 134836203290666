import React, { useState } from "react";
import Login_bg from "../../assets/img/login_bg.png";
import { MICROSOFT_LOGIN } from "../../utils/constants";
import "../../assets/styles/auth.css";

interface LoginPageProps {
  onLogin: (email: string, password: string) => void;
  loading: boolean;
  microsoftLogin: () => void;
}

const Login: React.FC<LoginPageProps> = ({
  onLogin,
  loading,
  microsoftLogin,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!email || !password) {
      // If email or password is empty, set error states to true
      setEmailError(!email);
      setPasswordError(!password);
      return;
    }
    onLogin(email, password);
  };

  const handleMicrosoftLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    window.open(MICROSOFT_LOGIN, "_self");
  };

  return (
    <>
      <section className="w-full h-full py-40 min-h-screen">
        <div className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full">
          <div className="container mx-auto px-4 h-full">
            <div className="flex items-center justify-between h-full">
              <div className="w-full lg:w-6/12 px-4">
                <img src={Login_bg} alt="Login_bg w-full" />
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-1 shadow-xl rounded-lg bg-white border-2">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-blueGray-700 text-4xl font-bold ft-family signIn_text">
                        Signin
                      </h6>
                    </div>
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                      {/* <div>
                        <label
                          className="block text-sm mb-2 ft-family"
                          htmlFor="grid-password"
                        >
                          Email ID <span>*</span>
                        </label>
                        <input
                          type="email"
                          className={`border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150 ${
                            emailError ? "user-input-error" : ""
                          }`}
                          placeholder="Email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError(false); // Reset error state on change
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <label
                          className="block text-sm mb-2 ft-family"
                          htmlFor="grid-password"
                        >
                          Password <span>*</span>
                        </label>
                        <input
                          type="password"
                          className={`border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150 ${
                            passwordError ? "user-input-error" : ""
                          }`}
                          placeholder="Password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(false); // Reset error state on change
                          }}
                        />
                      </div> */}
                      {/* <div className="text-center mt-6 ft-family">
                        <button
                          className={`bg-blueGray-800 text-white active:bg-blueGray-600 text-md uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 tracking-wide font-light ${
                            loading ? "opacity-50 pointer-events-none" : ""
                          }`}
                          type="button"
                          onClick={handleSubmit}
                        >
                          {loading ? "Loading..." : "LOGIN"}
                        </button>
                      </div>
                      <div className="text-center mt-2 ft-family">
                        <p>OR</p>
                      </div> */}
                      <div className="text-center mt-2 ft-family">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-md uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 tracking-wide font-light"
                          onClick={handleMicrosoftLogin}
                        >
                          LOGIN WITH MICROSOFT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Login_bg from "../../assets/img/login_bg.png";
// import "../../assets/styles/auth.css";

// interface LoginPageProps {
//   onLogin: () => void;
// }

// const Login: React.FC<LoginPageProps> = ({ onLogin }) => {
//   const [token, setToken] = useState<string | null>(null);

//   const handleMicrosoftLogin = async () => {
//     // Open the Microsoft login page in a new window
//     const microsoftLoginWindow = window.open(
//       "/auth",
//       "_blank"
//     );

//     // Add an event listener to capture the token when the new window changes its URL
//     const handleTokenCapture = (event: MessageEvent) => {
//       if (event.origin === "/auth") {
//         const token = event.data.token;
//         // Set the token in state
//         setToken(token);

//         // Close the Microsoft login window
//         microsoftLoginWindow?.close();

//         // You might want to redirect or perform other actions after capturing the token
//       }
//     };

//     window.addEventListener("message", handleTokenCapture);
//   };

//   return (
//     <>
//       <section className="w-full h-full py-40 min-h-screen">
//         <div className="absolute top-0 w-full h-full bg-white bg-no-repeat bg-full">
//           <div className="container mx-auto px-4 h-full">
//             <div className="flex items-center justify-between h-full">
//               <div className="w-full lg:w-6/12 px-4">
//                 <img src={Login_bg} alt="Login_bg w-full" />
//               </div>
//               <div className="w-full lg:w-4/12 px-4">
//                 <div className="relative flex flex-col min-w-0 break-words w-full mb-1 shadow-xl rounded-lg bg-white border-2">
//                   <div className="rounded-t mb-0 px-6 py-6">
//                     <div className="text-center mb-3">
//                       <h6 className="text-blueGray-700 text-4xl font-bold ft-family signIn_text">
//                         Signin
//                       </h6>
//                     </div>
//                   </div>
//                   <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
//                     {token ? (
//                       // If token is available, show a message or perform further actions
//                       <div>
//                         <p>Token captured: {token}</p>
//                         {/* You can also redirect the user or render other content */}
//                       </div>
//                     ) : (
//                       // If token is not available, show the login button
//                       <button
//                         className="bg-blueGray-800 text-white active:bg-blueGray-600 text-md uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 tracking-wide font-light"
//                         onClick={handleMicrosoftLogin}
//                       >
//                         LOGIN WITH MICROSOFT
//                       </button>
//                     )}
//                     <div className="text-center mt-3 ft-family">
//                       <Link to="/register" className="text-black text-xl">
//                         <small>Signup</small>
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Login;
