import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import UserDropdown from "components/Dropdowns/UserDropdown";
import "../../assets/styles/common.css";

const Sidebar: React.FC<{
  dispatch: any;
  userDetails?: any;
  employeeDetails?: any;
  onLogout: () => void;
}> = ({ onLogout, dispatch, userDetails, employeeDetails }) => {
  const location = useLocation();
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [showSearchEmployeeItem, setShowSearchEmployeeItem] = useState(false);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.data &&
      userDetails.data.roles.length > 0 &&
      userDetails.data.roles.some((role: any) => role.role_name === "manager")
    ) {
      setShowSearchEmployeeItem(true);
    } else {
      setShowSearchEmployeeItem(false);
    }
  }, [userDetails]);

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-95-px md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-4 top-95-px xsm:top-0 sidebar-bg">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}

          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown onLogout={onLogout} />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Skill Matrix
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={`text-xs uppercase py-3 font-bold block ${
                    location.pathname === "/employee-details"
                      ? "sidebar-links-text-active"
                      : ""
                  }`}
                  to="/employee-details"
                >
                  <i
                    className={`fas fa-tools mr-2 text-sm sidebar-links-text ${
                      location.pathname === "/employee-details"
                        ? "sidebar-links-text-active"
                        : "text-blueGray-300"
                    }`}
                  ></i>{" "}
                  Employee Details
                </Link>
              </li>

              {showSearchEmployeeItem && (
                <li className="items-center">
                  <Link
                    className={`text-xs uppercase py-3 font-bold block ${
                      location.pathname === "/search-employee"
                        ? "sidebar-links-text-active"
                        : ""
                    }`}
                    to="/search-employee"
                  >
                    <i
                      className={`fas fa-table mr-2 text-sm sidebar-links-text ${
                        location.pathname === "/search-employee"
                          ? "sidebar-links-text-active"
                          : ""
                      }`}
                    ></i>{" "}
                    Search Employee
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.auth.userDetials,
    employeeDetails: state.auth.emplyoeeDetails,
  };
};

export default connect(mapStateToProps)(Sidebar);
