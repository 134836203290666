import React, { useEffect, useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import MultiSelectInputSearch from "components/UserInputs/MultiSelectInput";
import DownloadDropdown from "components/Dropdowns/DownloadData";
import AllSkillsList from "components/SkillsList/AllSkills";
import { fetchAllSkillsByparamsAction } from "../../store/authSlice";
import { connect } from "react-redux";
import Button from "components/Buttons/Button";

const AllEmplyoeeSkills: React.FC<{
  dispatch: any;
  allSkills: any;
  allSkillsByParams: any;
  allSkillsRecords: any;
}> = ({ dispatch, allSkills, allSkillsByParams, allSkillsRecords }) => {
  const [options, setOptions] = useState([]);
  const [catOpts, setCatOpts] = useState([]);
  const [searchTypeOptions, setSearchTypeOptions] = useState([
    {
      label: "Name",
      value: "Name",
    },
    {
      label: "Skills",
      value: "Skills",
    },
  ]);
  const [nameOptions, setNameOptions] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(null);

  useEffect(() => {
    if (allSkills && allSkills.data) {
      const transformedOptions = allSkills.data.map((skill: any) => ({
        value: skill.skill_id,
        label: skill.skill,
      }));
      const uniqueCategories = {};

      const categoryOpts = allSkills.data
        .filter((skill: any) => {
          if (!uniqueCategories[skill.category]) {
            uniqueCategories[skill.category] = true;
            return true;
          }
          return false;
        })
        .map((sk) => ({
          value: sk.category,
          label: sk.category,
        }));

      setCatOpts(categoryOpts);
      setOptions(transformedOptions);
    }
  }, [allSkills]);

  useEffect(() => {
    if (allSkillsRecords && allSkillsRecords.data) {
      // console.log(allSkillsRecords);
      const transformedOptions = allSkillsRecords.data.data.map(
        (item: any) => ({
          value: item.full_name,
          label: item.full_name,
        })
      );
      setNameOptions(transformedOptions);
    }
  }, [allSkillsRecords]);

  const handleChange = (selectedOptions: any[], key: string) => {
    if (key === "category") {
      setSelectedCategories(selectedOptions);
    } else if (key === "skill") {
      setSelectedSkills(selectedOptions);
    }
  };

  const onSearch = () => {
    let catStr = "";
    let skillStr = "";
    if (selectedSkills.length > 0) {
      skillStr =
        "?skills=" + selectedSkills.map((item: any) => item.label).join(",");
    }

    if (selectedCategories && Object.keys(selectedCategories).length) {
      catStr = "?";
      if (selectedSkills.length) {
        catStr = "&";
      }
      catStr += "category=" + selectedCategories["label"];
    }

    const paramStr = `${skillStr}${catStr}`;

    dispatch(fetchAllSkillsByparamsAction({ paramsURL: paramStr }))
      .then((response: any) => {
        // console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleTypeChange = (selectedOption: any) => {
    dispatch(fetchAllSkillsByparamsAction({ paramsURL: "" }));
    setSelectedType(selectedOption);
  };

  const handleNameChange = (selectedOption: any) => {
    setSelectedName(selectedOption);
    let param = `?full_name=${selectedOption.value}`;
    dispatch(fetchAllSkillsByparamsAction({ paramsURL: param }))
      .then((response: any) => {
        // console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  async function downloadAllRecords(str: string) {
    const timeStamp = Date.now();
    const zip = new JSZip();

    if (str === "PDF" || str === "WORD") {
      if (allSkillsByParams.data && allSkillsByParams.data.data.length > 0) {
        const fetchPromises = allSkillsByParams.data.data.map(
          async (item: { resume_url: string }) => {
            if (
              item.resume_url &&
              (item.resume_url.endsWith(".pdf") ||
                item.resume_url.endsWith(".docx") ||
                item.resume_url.endsWith(".doc"))
            ) {
              const response = await fetch(item.resume_url);
              const blob = await response.blob();
              // Add the file to the zip
              zip.file(item.resume_url.split("/").pop() || "", blob);
            }
          }
        );

        Promise.all(fetchPromises)
          .then(() => {
            zip.generateAsync({ type: "blob" }).then((content) => {
              // Save the zip file
              if (str === "PDF") {
                saveAs(content, `resumes-${timeStamp}.zip`);
              } else if (str === "WORD") {
                saveAs(content, `resumes-${timeStamp}.docx`);
              }
            });
          })
          .catch((error) => {
            console.error("Error fetching files:", error);
          });
      }
    }

    if (str === "CSV") {
      const truncateText = (text, maxLength) => {
        return text.length > maxLength
          ? text.substring(0, maxLength) + "..."
          : text;
      };

      const skillsToString = async (skillsArray) => {
        const skills = await Promise.all(
          skillsArray.map(async (skill) => {
            return `${truncateText(skill.skill, 50)} (${skill.competency})`;
          })
        );
        return skills.join("; ");
      };

      const categoryToString = async (skillsArray) => {
        const skills = await Promise.all(
          skillsArray.map(async (skill) => {
            return truncateText(skill.category, 50);
          })
        );
        return skills.join("; ");
      };

      if (allSkillsByParams.data && allSkillsByParams.data.data.length > 0) {
        const csvContent = [
          [
            "Employee Code",
            "Full Name",
            "Category",
            "Employee Skills",
            "Email",

            "Relevant Experience",
            "Total Experience",
            "Resume Link",
          ],
        ];

        await Promise.all(
          allSkillsByParams.data.data.map(async (employee) => {
            const employeeSkills = await skillsToString(
              employee.employee_skills_id
            );
            const employeeSkillCategory = await categoryToString(
              employee.employee_skills_id
            );
            const email = employee.user_obj ? employee.user_obj.email : "";
            csvContent.push([
              employee.employee_code,
              employee.full_name,
              employeeSkillCategory,
              employeeSkills.replaceAll(",", " - "),
              email,

              employee.relevantexp,
              employee.totalexp,
              employee.resume_url,
            ]);
          })
        );

        const csvString = csvContent.map((row) => row.join(",")).join("\n");

        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `employees-${timeStamp}.csv`);
      }
    }
  }

  return (
    <>
      <div className="ml-auto h-screen">
        <div className="flex flex-row justify-between items-center mx-auto w-full m-4">
          <div
            className="flex flex-row justify-center items-center"
            style={{ width: "60%" }}
          >
            <div style={{ width: "30%" }}>
              <p>Search by</p>
              <MultiSelectInputSearch
                options={searchTypeOptions}
                selectedSkills={selectedType}
                handleSkillsChange={handleTypeChange}
                isMultiRequired={false}
                placeholder={"Select Type"}
              />
            </div>
            <div className="ml-2" style={{ width: "70%" }}>
              {selectedType && selectedType.value === "Skills" ? (
                <>
                  <p>Search Skills</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ width: "45%" }}>
                      <MultiSelectInputSearch
                        options={catOpts}
                        selectedSkills={selectedCategories}
                        handleSkillsChange={(e) => {
                          handleChange(e, "category");
                        }}
                        isMultiRequired={false}
                        isClearable={true}
                        placeholder={"Select Category"}
                      />
                    </span>
                    <span style={{ width: "53%" }}>
                      <MultiSelectInputSearch
                        options={options}
                        selectedSkills={selectedSkills}
                        handleSkillsChange={(e) => {
                          handleChange(e, "skill");
                        }}
                        isMultiRequired={true}
                        placeholder={"Select Skills"}
                      />
                    </span>
                  </div>
                </>
              ) : null}

              {selectedType && selectedType.value === "Name" ? (
                <>
                  <p>Search Name</p>
                  <MultiSelectInputSearch
                    options={nameOptions}
                    selectedSkills={selectedName}
                    handleSkillsChange={handleNameChange}
                    isMultiRequired={false}
                    placeholder={"Select Name"}
                  />
                </>
              ) : null}
            </div>
          </div>
          {selectedType && selectedType.value === "Name" ? null : (
            <div className="mt-6">
              <Button
                backgroundColor="black"
                text="Search"
                onClick={() => onSearch()}
              />
            </div>
          )}
          <div>
            <DownloadDropdown click={(str) => downloadAllRecords(str)} />
          </div>
        </div>

        <div className="w-full mt-4">
          <AllSkillsList />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    allSkills: state.auth.allSkills,
    allSkillsByParams: state.auth.allSkillsByParams,
    allSkillsRecords: state.auth.allSkillsRecords,
  };
};

export default connect(mapStateToProps)(AllEmplyoeeSkills);
