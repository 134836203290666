import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import store from "store/store";

import App from "App";

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
