// Layout.tsx
import * as React from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
// import FooterAdmin from "components/Footers/FooterAdmin";

interface LayoutProps {
  onLogout: () => void;
  isLoggedIn: boolean;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ onLogout, isLoggedIn, children }) => {
  return (
    <>
      <div>
        <AdminNavbar onLogout={onLogout} isLoggedIn={isLoggedIn}/>
        <div className="relative md:ml-64 bg-white">
          <Sidebar onLogout={onLogout} />
          <div>{children}</div>
        </div>
        {/* <FooterAdmin /> */}
      </div>
    </>
  );
};

export default Layout;
