import React from "react";
import Select from "react-select";

interface MultiSelectInputSearchProps {
  options: any[];
  selectedSkills: any[];
  handleSkillsChange: (selectedOptions: any[]) => void;
  isMultiRequired?: boolean;
  placeholder?: string;
  isClearable?: boolean
}

const MultiSelectInputSearch: React.FC<MultiSelectInputSearchProps> = ({
  options,
  selectedSkills,
  handleSkillsChange,
  isMultiRequired,
  placeholder,
  isClearable = false
}) => {
  return (
    <div className="relative flex items-start flex-col">
      <div className="flex items-center mt-2">
        {isMultiRequired ? (
          <Select
            options={options}
            isMulti
            value={selectedSkills}
            onChange={handleSkillsChange}
            placeholder={placeholder}
            className="w-full"
          />
        ) : (
          <Select
            options={options}
            isClearable={isClearable}
            isMulti={false}
            value={selectedSkills}
            onChange={handleSkillsChange}
            placeholder={placeholder}
            className="w-full"
          />
        )}
      </div>
    </div>
  );
};

export default MultiSelectInputSearch;
