import React from "react";
import { FaStar } from "react-icons/fa";

interface Rating {
  level: string;
  rating: string;
}

interface Option {
  value: Rating;
  label: string;
  icon?: string;
}

interface DropdownProps {
  label: string;
  id: string;
  name: string;
  value: any; // You might want to replace 'any' with a more specific type if applicable
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
}

function DropdownWithIcon({
  label,
  id,
  name,
  value,
  onChange,
  options,
}: DropdownProps) {
  return (
    <div className="mb-4">
      <div className="flex items-center">
        <FaStar className="ml-2" size={"12px"} />
        <label
          htmlFor={id}
          className="block text-sm font-semibold text-gray-700 ml-2"
        >
          {label}
        </label>
      </div>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value={"select"}>Select Competency</option>
        {options.map((option) => (
          <option key={option.label} value={option.label}>
            {option.value.level} - {option.value.rating}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropdownWithIcon;
