import React, { useState, useEffect } from "react";
import Select from "react-select";

function DropdownNewSkill({ label, id, name, value, onChange, options }) {
  const [ddVal, setDdVal] = useState(null);

  useEffect(() => {
    setDdVal([{ label: value, value: value }]);
  }, [value]);

  const handleChange = (event) => {
    setDdVal(event);
    onChange(event);
  };
  return (
    <div className="mb-4">
      <label htmlFor={id} className="block text-sm font-semibold text-gray-700">
        {label}
      </label>
      <Select
        id={id}
        name={name}
        value={ddVal}
        options={options}
        onChange={handleChange}
        className="mt-1 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  );
}

export default DropdownNewSkill;
