import Axios from "axios";

Axios.interceptors.request.use(
  function (config) {
    // add loader
    if (!document.body.classList.contains("loading-indicator")) {
        document.body.classList.add("loading-indicator");
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    // remove loader
    document.body.classList.remove("loading-indicator");

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
