import React, { ReactNode } from "react";

interface Tab {
  title: string;
  content: ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: number;
  onTabChange: (index: number) => void;
}

const EmpDetailsTabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  onTabChange,
}) => {
  return (
    <div>
      <div className="flex emp-details-tabs-container">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 py-2 ${
              activeTab === index
                ? "bg-gray-200 text-gray-800 emp-details-tabs"
                : "bg-gray-100 text-gray-600"
            }`}
            onClick={() => onTabChange(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="p-4">{tabs[activeTab].content}</div>
    </div>
  );
};

export default EmpDetailsTabs;
