import React, { useState, useRef, useEffect } from "react";
import ToastComponent from "../../components/Toast/ToastComponent";

interface Props {
  label: string;
  id: string;
  onChange: (file: File) => void;
  width: string;
  height: string;
  initialImageUrl?: string | null;
}

function ImageUploadWithPreview({
  label,
  id,
  onChange,
  width,
  height,
  initialImageUrl,
}: Props) {
  const [previewImage, setPreviewImage] = useState(initialImageUrl);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };

      if (validateFile(file)) {
        reader.readAsDataURL(file);
        if (onChange) {
          onChange(file);
        }
      } else {
        e.target.value = "";
        setPreviewImage(initialImageUrl || null);
        if (onChange) {
          onChange(null);
        }
        ToastComponent.error("Please choose a JPG or PNG file");
      }
    } else {
      setPreviewImage(initialImageUrl || null);
    }
  };

  const validateFile = (file: File): boolean => {
    const validTypes = ["image/jpeg", "image/png"];
    if (validTypes.includes(file.type)) {
      return true;
    }
    return false;
  };

  const handlePreviewClick = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  useEffect(() => {
    if (initialImageUrl) {
      setPreviewImage(initialImageUrl);
    }
  }, [initialImageUrl]);

  return (
    <div className="mb-4" style={{ width: width, height: height }}>
      <label htmlFor={id} className="block text-sm font-semibold text-gray-700">
        {label}
      </label>
      <div
        className="mt-1 relative border border-gray-800 rounded-md"
        style={{ width: width, height: height, background: "#f8f9f5" }}
      >
        <input
          type="file"
          id={id}
          ref={fileInputRef}
          onChange={handleImageChange}
          accept="image/jpeg, image/png"
          className="sr-only"
        />
        <img
          src={previewImage}
          alt="Preview"
          className={
            previewImage
              ? "block h-auto w-full rounded-md cursor-pointer"
              : "hidden"
          }
          onClick={handlePreviewClick}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
        {!previewImage ? (
          <label
            htmlFor={id}
            className="cursor-pointer absolute inset-0 h-full w-full flex items-center justify-center rounded-md"
          >
            <span className="text-sm font-medium text-gray-700">
              Choose a file
            </span>
          </label>
        ) : null}
      </div>
    </div>
  );
}

export default ImageUploadWithPreview;
