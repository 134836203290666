import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ClientData {
  client: string;
  max: number;
  min: number;
  avg: number;
}

const chartData: ClientData[] = [
  { client: "Client 1", max: 5, min: 2, avg: 2 },
  { client: "Client 2", max: 8, min: 5, avg: 8 },
  { client: "Client 3", max: 10, min: 5, avg: 10 },
  { client: "Client 4", max: 10, min: 5, avg: 10 },
  { client: "Client 1", max: 5, min: 2, avg: 2 },
  { client: "Client 2", max: 8, min: 5, avg: 8 },
  { client: "Client 3", max: 10, min: 5, avg: 10 },
  { client: "Client 4", max: 10, min: 5, avg: 10 },
];

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Average Length of Stay",
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // Disable x-axis grid lines
      },
      stacked: true,
    },
    y: {
      grid: {
        display: false, // Disable x-axis grid lines
      },
      stacked: true,
      ticks: {
        callback: (value: any, index: number) => {
          if (index % 2 === 0) {
            // Display years text on every alternate tick
            return value + " years";
          } else {
            return ""; // Hide years text on other ticks
          }
        },
      },
    },
  },
};

const barChartData = {
  labels: chartData.map((item) => item.client),
  datasets: [
    {
      label: "Data",
      data: chartData.map((item) => item.avg),
      backgroundColor: "rgba(211, 211, 211, 0.5)",
      barThickness: 20, // Set the bar width here
    },
  ],
};

const maxStay = Math.max(...chartData.map((item) => item.max));
const minStay = Math.min(...chartData.map((item) => item.min));
const avgStay =
  chartData.reduce((sum, item) => sum + item.avg, 0) / chartData.length;

function ClientEngAvgChart() {
  return (
    <div className="container mx-auto border-2 shadow-sm rounded p-4 client-table-eng">
      <h1 className="text-xl font-bold mb-4">Average Length of Stay</h1>
      <Bar options={options} data={barChartData} />
      <div className="mt-12">
        <div className="flex items-center justify-between">
          <div className="flex items-start flex-col">
            <p>Max. Length of stay</p>
            <p className="font-bold">{maxStay} years</p>
          </div>
          <div className="flex items-start flex-col">
            <p>Min. Length of stay</p>
            <p className="font-bold">{minStay} years</p>
          </div>
          <div className="flex items-start flex-col">
            <p>Avg. Length of stay</p>
            <p className="font-bold">{avgStay} years</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientEngAvgChart;
