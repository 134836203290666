import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { IoReloadCircle } from "react-icons/io5";

interface DropdownItem {
  id: number;
  name: string;
}

const SearchInputWithDropdown: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
    { id: 3, name: "Item 3" },
    { id: 4, name: "Item 4" },
  ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowDropdown(value !== "");
  };

  const handleDropdownItemClick = (item: DropdownItem) => {
    setSearchTerm(item.name);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  return (
    <div className="flex">
      <div className="relative lg:w-6/12 xl:w-3/12 mt-6">
        <div className="relative flex items-center">
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Enter Employee ID/Name"
            className="border border-gray-300 rounded-md pl-4 pr-10 py-2 focus:outline-none focus:border-blue-500 w-full"
            onClick={() => setShowDropdown(searchTerm !== "")}
          />
          <button
            onClick={toggleDropdown}
            className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600 focus:outline-none"
          >
            <FaAngleDown fontSize={24} />
          </button>
        </div>
        {showDropdown && (
          <div className="absolute z-10 mt-2 w-full rounded-md bg-white shadow-lg">
            <ul className="w-full">
              {dropdownItems
                .filter((item) =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleDropdownItemClick(item)}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                  >
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <div className="relative lg:w-6/12 xl:w-4/12 mt-6 ml-10 flex items-center justify-center">
        <div className="relative flex">
          <p className="mr-2">Data updated till 11.41 am of dd/mm/yyyy</p>
          <IoReloadCircle fontSize={24}/>
        </div>
      </div>
    </div>
  );
};

export default SearchInputWithDropdown;
