import React from "react";

interface ClientEngagement {
  clientName: string;
  clientId: number;
  yoe: number;
  fromDate: string;
  toDate: string;
  projects: number;
}

const clientEngagements: ClientEngagement[] = [
  {
    clientName: "Client 1",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 2,
  },
  {
    clientName: "Client 2",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 3,
  },
  {
    clientName: "Client 3",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 2,
  },
  {
    clientName: "Client 4",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 4,
  },
  {
    clientName: "Client 5",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 3,
  },
  {
    clientName: "Client 4",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 4,
  },
  {
    clientName: "Client 5",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 3,
  },
  {
    clientName: "Client 1",
    clientId: 1234567,
    yoe: 30,
    fromDate: "30/10/2022",
    toDate: "30/10/2022",
    projects: 2,
  },
];

const ClientEngagementsTable = () => {
  return (
    <div className="mx-auto overflow-x-auto shadow-sm rounded-lg border-2 client-table-eng">
      <h1 className="text-xl font-bold mt-4 ml-2">Client Engagements</h1>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-2">
        <thead className="text-sm text-gray-700 bg-gray-50 dark:bg-gray-700 sticky top-0 border-b-2 border-gray-200">
          <tr>
            <th scope="col" className="px-4 py-2">
              Client Name
            </th>
            <th scope="col" className="px-4 py-2">
              Client ID
            </th>
            <th scope="col" className="px-4 py-2">
              YOE
            </th>
            <th scope="col" className="px-4 py-2">
              From
            </th>
            <th scope="col" className="px-4 py-2">
              To
            </th>
          </tr>
        </thead>
        <tbody>
          {clientEngagements.map((clientEngagement) => (
            <tr
              key={clientEngagement.clientId}
              className="odd:border-b-gray-200 even:border-b-gray-800"
            >
              <td className="px-4 py-3 font-medium text-gray-900 dark:text-white">
                {clientEngagement.clientName}
              </td>
              <td className="px-4 py-3">{clientEngagement.clientId}</td>
              <td className="px-4 py-3">{clientEngagement.yoe}</td>
              <td className="px-4 py-3">{clientEngagement.fromDate}</td>
              <td className="px-4 py-3">{clientEngagement.toDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientEngagementsTable;
