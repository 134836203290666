import React from "react";

interface PageTitleProps {
  preTitleIcon?: React.ReactElement;
  title: string;
  postTitleIcon?: React.ReactElement;
  click?: (a: any) => null | void;
  clickType?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  preTitleIcon,
  title,
  postTitleIcon,
  click,
  clickType,
}) => {
  return (
    <>
      <div className="flex flex-wrap items-center w-full lg:w-6/12 xl:w-3/12">
        {preTitleIcon}
        <h1 className="text-xl ml-2 mr-2">{title}</h1>
        <span onClick={() => click(clickType)} style={{ cursor: "pointer" }}>
          {postTitleIcon}
        </span>
      </div>
    </>
  );
};

export default PageTitle;
