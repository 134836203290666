import React, { ReactNode } from "react";
import "./Modal.css";

interface ButtonProps {
  label: string;
  className: string;
  onClick: () => void;
}

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  body: ReactNode;
  footerButtons: ButtonProps[];
}

function Modal({
  showModal,
  setShowModal,
  title,
  body,
  footerButtons,
}: ModalProps) {
  return (
    <>
      {showModal && (
        <>
          <div className="modal-overlay">
            <div className="modal-container">
              <div className="modal-header">
                <h4>{title}</h4>
                <button onClick={() => setShowModal(false)}>×</button>
              </div>
              <div className="modal-body">{body}</div>
              <div className="modal-footer">
                {footerButtons.map((button, index) => (
                  <button
                    key={index}
                    className={button.className}
                    onClick={button.onClick}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Modal;
