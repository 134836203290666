import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

interface SearchBarProps {
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
}

const SearchInputOnly: React.FC<SearchBarProps> = ({
  placeholder = "search term",
  onSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="relative flex items-start flex-col">
      <p>Skillset Search</p>
      <div className="flex items-center mt-2">
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="border border-gray-300 rounded-md pl-4 pr-10 py-2 focus:outline-none focus:border-blue-500 w-full"
        />
        <div className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600">
          <FaSearch />
        </div>
      </div>
    </div>
  );
};

export default SearchInputOnly;
