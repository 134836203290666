import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  userLogin,
  saveEmployeeDetails,
  updateEmployeeDetails,
  userLocations,
  getUserDetails,
  getEmployeeSkills,
  getEmployeeDetails,
  fetchEmployeeSpecificSkills,
  fetchEmployeeAllSkills,
  fetchEmployeeAllSkillsCategory,
  fetchAllSkillsByparams,
  fetchMySkills,
  fetchMySkillsRecords,
  saveSkillDetails,
  deleteSkillDetails,
  updateSkillDetails,
} from "../utils/api";

interface AuthState {
  token: string | null;
  locations: Object;
  userDetials: Object | null;
  emplyoeeSkills: Object | null;
  emplyoeeDetails: Object | null;
  emplyoeeSpecificSkills: Object | null;
  allSkills: Object | null;
  allSkillsByParams: Object | null;
  allSkillsRecords: Object | null;
  mySkills: Object | null;
}

const initialState: AuthState = {
  token: localStorage.getItem("token"),
  locations: {},
  userDetials: {},
  emplyoeeSkills: {},
  emplyoeeDetails: {},
  emplyoeeSpecificSkills: {},
  allSkills: {},
  allSkillsRecords: {},
  allSkillsByParams: {},
  mySkills: {},
};

// Thunks for async actions
export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }: { email: string; password: string }) => {
    const response = await userLogin(email, password);
    return response.token;
  }
);

export const fetchEmployeeLocations = createAsyncThunk(
  "auth/locations",
  async () => {
    const response = await userLocations();
    return response;
  }
);

export const getUserDetailsAction = createAsyncThunk(
  "auth/getUserDetails",
  async () => {
    const response = await getUserDetails();
    return response;
  }
);

export const fetchEmployeeSkills = createAsyncThunk(
  "auth/getEmplyoeeSkills",
  async () => {
    const response = await getEmployeeSkills();
    return response;
  }
);

export const saveEmployeeDetailsAction = createAsyncThunk(
  "auth/saveEmployeeDetails",
  async ({ params }: { params: object }) => {
    const response = await saveEmployeeDetails(params);
    return response;
  }
);

export const updateEmployeeDetailsAction = createAsyncThunk(
  "auth/updateEmployeeDetails",
  async ({ params, id }: { params: object; id: number }) => {
    const response = await updateEmployeeDetails(params, id);
    return response;
  }
);

export const fetchEmployeeDetails = createAsyncThunk(
  "auth/getEmplyoeeDetails",
  async ({ user }: { user: object }) => {
    const response = await getEmployeeDetails(user);
    return response;
  }
);

export const fetchEmpSpecificSkills = createAsyncThunk(
  "auth/getEmployeeSpecificSkills",
  async ({ user }: { user: object }) => {
    const response = await fetchEmployeeSpecificSkills(user);
    return response;
  }
);

export const fetchAllSkills = createAsyncThunk(
  "auth/getAllSkills",
  async () => {
    const response = await fetchEmployeeAllSkills();
    return response;
  }
);
export const fetchAllSkillsCategory = createAsyncThunk(
  "auth/getAllSkillsCategory",
  async () => {
    const response = await fetchAllSkillsCategory();
    return response;
  }
);

export const fetchAllSkillsByparamsAction = createAsyncThunk(
  "auth/getAllSkillsByParams",
  async ({ paramsURL }: { paramsURL?: string }) => {
    const response = await fetchAllSkillsByparams(paramsURL);
    return response;
  }
);

export const fetchMySkillsAction = createAsyncThunk(
  "auth/getMySkills",
  async ({ user }: { user: object }) => {
    const response = await fetchMySkills(user);
    return response;
  }
);

export const fetchAllSkillsRecords = createAsyncThunk(
  "auth/getAllSkillsRecords",
  async () => {
    const response = await fetchMySkillsRecords();
    return response;
  }
);

export const saveSkillDetailsAction = createAsyncThunk(
  "auth/saveSkillDetails",
  async ({ params }: { params: object }) => {
    const response = await saveSkillDetails(params);
    return response;
  }
);

export const deleteSkillDetailsAction = createAsyncThunk(
  "auth/deleteSkillDetails",
  async ({ skillId }: { skillId: number }) => {
    const response = await deleteSkillDetails(skillId);
    return response;
  }
);

export const updateSkillDetailsAction = createAsyncThunk(
  "auth/updateSkillDetails",
  async ({ skillId, params }: { skillId: number; params: object }) => {
    const response = await updateSkillDetails(skillId, params);
    return response;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem("token", action.payload);
      } else {
        localStorage.removeItem("token");
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(fetchEmployeeLocations.fulfilled, (state, action) => {
      state.locations = action.payload;
    });
    builder.addCase(getUserDetailsAction.fulfilled, (state, action) => {
      state.userDetials = action.payload;
    });
    builder.addCase(fetchEmployeeSkills.fulfilled, (state, action) => {
      state.emplyoeeSkills = action.payload;
    });
    builder.addCase(fetchEmployeeDetails.fulfilled, (state, action) => {
      state.emplyoeeDetails = action.payload;
    });
    builder.addCase(fetchEmployeeDetails.rejected, (state, action) => {
      console.log("fetch employee details failed");
    });
    builder.addCase(saveEmployeeDetailsAction.fulfilled, (state, action) => {
      // Handle state update if needed
    });
    builder.addCase(saveEmployeeDetailsAction.rejected, (state, action) => {
      // Handle the failure case here
      console.error("Save Employee Details Failed");
    });
    builder.addCase(updateEmployeeDetailsAction.fulfilled, (state, action) => {
      // Handle state update if needed
    });
    builder.addCase(fetchEmpSpecificSkills.fulfilled, (state, action) => {
      state.emplyoeeSpecificSkills = action.payload;
    });
    builder.addCase(fetchEmpSpecificSkills.rejected, (state, action) => {
      // Handle the failure case here
      console.error("fetchEmployeeSpecificSkills Failed");
    });
    builder.addCase(fetchAllSkills.fulfilled, (state, action) => {
      state.allSkills = action.payload;
    });
    builder.addCase(fetchAllSkills.rejected, (state, action) => {
      // Handle the failure case here
      console.error("fetchAllSkills Failed");
    });
    builder.addCase(fetchAllSkillsRecords.fulfilled, (state, action) => {
      state.allSkillsRecords = action.payload;
    });
    builder.addCase(fetchAllSkillsRecords.rejected, (state, action) => {
      // Handle the failure case here
      console.error("fetchAllSkills Failed");
    });
    builder.addCase(fetchAllSkillsByparamsAction.fulfilled, (state, action) => {
      state.allSkillsByParams = action.payload;
    });
    builder.addCase(fetchAllSkillsByparamsAction.rejected, (state, action) => {
      // Handle the failure case here
      console.error("fetchAllSkills By Params Failed");
    });
    builder.addCase(fetchMySkillsAction.fulfilled, (state, action) => {
      state.mySkills = action.payload;
    });
    builder.addCase(fetchMySkillsAction.rejected, (state, action) => {
      // Handle the failure case here
      console.error("fetchAllSkills By Params Failed");
    });
    builder.addCase(saveSkillDetailsAction.fulfilled, (state, action) => {
      // Handle state update if needed
    });
    builder.addCase(saveSkillDetailsAction.rejected, (state, action) => {
      // Handle the failure case here
      console.error("Save Employee Skills Failed");
    });
    builder.addCase(deleteSkillDetailsAction.fulfilled, (state, action) => {
      // Handle state update if needed
    });
    builder.addCase(deleteSkillDetailsAction.rejected, (state, action) => {
      // Handle the failure case here
      console.error("Delete Employee Skills Failed");
    });
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
