import React, { useEffect, useState } from "react";
import EmpDetailsTabs from "../../components/Tabs/EmpDetailsTabs";
import MyProfile from "views/employees/MyProfile";
import MySkills from "views/employees/MySkills";
import CreateSkill from "views/employees/CreateSkill";
import UpdateSkill from "views/employees/UpdateSkill";
import CreateProfile from "views/employees/CreateProfile";
import UpdateProfile from "views/employees/UpdateProfile";
import { connect } from "react-redux";
import {
  fetchEmployeeLocations,
  getUserDetailsAction,
  fetchMySkillsAction,
  fetchEmployeeDetails,
} from "../../store/authSlice";
import "../../assets/styles/common.css";

const EmployeeDetails: React.FC<{
  dispatch: any;
  userDetails?: any;
  emplyoeeDetails?: any;
}> = ({ dispatch, userDetails, emplyoeeDetails }) => {
  const [showMyProfile, setShowMyProfile] = useState<boolean>(false);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState<boolean>(false);
  const [showSkillsPage, setShowSkillsPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedSkillObj, setSelectedSkillObj] = useState<any>({});

  useEffect(() => {
    if (dispatch) {
      dispatch(getUserDetailsAction());
      dispatch(fetchEmployeeLocations());
    }
  }, [dispatch]);

  useEffect(() => {
    if (userDetails && userDetails.data) {
      if (userDetails.data.employee === null) {
        setIsFirstTimeUser(true);
        setIsLoading(false);
      } else if (Object.keys(userDetails.data.employee).length > 0) {
        dispatch(fetchEmployeeDetails({ user: userDetails.data.employee }))
          .then((response: any) => {
            setShowMyProfile(true);
            setIsLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
            setIsLoading(false);
          });
        dispatch(fetchMySkillsAction({ user: userDetails.data.employee }));
      } else {
        setIsFirstTimeUser(false);
        setShowMyProfile(false);
        setIsLoading(false);
      }
    }
  }, [dispatch, userDetails]);

  const handleProfileDisplay = () => {
    if (isFirstTimeUser) {
      return <CreateProfile redirectClick={() => window.location.reload()} />;
    }
    if (showMyProfile) {
      return <MyProfile click={() => setShowMyProfile(false)} />;
    } else {
      return <UpdateProfile redirectClick={() => window.location.reload()} />;
    }
  };

  const showUpdateSkillsPage = (obj: any) => {
    setSelectedSkillObj(obj);
    setShowSkillsPage(true);
  };

  function refreshSkills() {
    setSelectedSkillObj({});
    setShowSkillsPage(false);
    dispatch(fetchMySkillsAction({ user: userDetails.data.employee }));
  }

  const handleSkillsDisplay = () => {
    if (showSkillsPage && Object.keys(selectedSkillObj).length === 0) {
      return <CreateSkill click={() => refreshSkills()} />;
    }

    if (showSkillsPage && Object.keys(selectedSkillObj).length > 0) {
      return (
        <UpdateSkill
          skillobj={selectedSkillObj}
          click={() => refreshSkills()}
        />
      );
    }

    return (
      <MySkills
        click={() => setShowSkillsPage(true)}
        updateclick={(obj) => showUpdateSkillsPage(obj)}
        setLoading={setIsLoading} 
      />
    );
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    if (index === 0) {
      dispatch(fetchMySkillsAction({ user: userDetails.data.employee }));
    }
  };

  const tabs = [
    { title: "My Profile", content: handleProfileDisplay() },
    { title: "My Skills", content: handleSkillsDisplay() },
    // { title: "My Client Engagements", content: "Content for Tab 3" },
  ];

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className="mt-20 py-4 px-8 w-3/4 ml-auto h-screen">
      <div className="px-4 md:px-10 mx-auto w-full">
        <EmpDetailsTabs
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.auth.userDetials,
    emplyoeeDetails: state.auth.emplyoeeDetails,
  };
};

export default connect(mapStateToProps)(EmployeeDetails);
