import React, { useState, useEffect } from "react";

interface ToggleSwitchProps {
  title?: string;
  onChange: (isChecked: boolean) => void;
  initialValue?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  title,
  onChange,
  initialValue,
}) => {
  const [isChecked, setIsChecked] = useState(initialValue || false); // Use initialValue prop if provided, otherwise default to false

  useEffect(() => {
    // Update the checked state if initialValue prop changes
    if (initialValue !== undefined) {
      setIsChecked(initialValue);
    }
  }, [initialValue]);

  const toggleChecked = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onChange(newValue);
  };

  return (
    <div className="flex items-center">
      <label className="toggle-switch ml-2">
        <input type="checkbox" checked={isChecked} onChange={toggleChecked} />
        <span className="slider"></span>
      </label>
      <p className="ml-2">{title}</p>
    </div>
  );
};

export default ToggleSwitch;
