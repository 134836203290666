import * as React from "react";
import { createPopper, Instance } from "@popperjs/core";
import { CgProfile } from "react-icons/cg";

interface UserDropdownProps {
  onLogout: () => void;
  urlImg?: string | null;
}

const UserDropdown: React.FC<UserDropdownProps> = ({ onLogout, urlImg }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] =
    React.useState<boolean>(false);
  const btnDropdownRef = React.useRef<HTMLAnchorElement>(null);
  const popoverDropdownRef = React.useRef<HTMLDivElement>(null);
  const popperInstanceRef = React.useRef<Instance | null>(null);

  const openDropdownPopover = () => {
    if (btnDropdownRef.current && popoverDropdownRef.current) {
      popperInstanceRef.current = createPopper(
        btnDropdownRef.current,
        popoverDropdownRef.current,
        {
          placement: "bottom-start",
        }
      );
      setDropdownPopoverShow(true);
    }
  };

  const closeDropdownPopover = () => {
    if (popperInstanceRef.current) {
      popperInstanceRef.current.destroy();
    }
    setDropdownPopoverShow(false);
  };

  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          

{urlImg ? (
  <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full overflow-hidden">
    <img
      alt="User Profile"
      className="w-full h-full rounded-full align-middle border-none"
      src={urlImg}
    />
  </span>
) : (
  <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full overflow-hidden">
    <CgProfile size={40} color="lightgrey" />
  </span>
)}

        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={`${
          dropdownPopoverShow ? "block" : "hidden"
        } bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48`}
      >
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={(e) => {
            e.preventDefault();
            onLogout();
          }}
        >
          Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
