// Layout.tsx
import * as React from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";

interface LayoutProps {
  onLogout: () => void;
  isLoggedIn: boolean;
  children: React.ReactNode;
}

const AuthLayout: React.FC<LayoutProps> = ({
  onLogout,
  isLoggedIn,
  children,
}) => {
  return (
    <div>
      <AdminNavbar onLogout={onLogout} isLoggedIn={isLoggedIn}/>
      <div>{children}</div>
    </div>
  );
};

export default AuthLayout;
