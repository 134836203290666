import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const SearchEmplyoeeTable: React.FC<{
  colDefs: any;
  rowData?: any;
  height?: any;
}> = ({ colDefs = [], rowData = [], height = "100%" }) => {
  return (
    <div
      className={"ag-theme-quartz"}
      style={{ width: "100%", height: height, overflow: "auto" }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        domLayout="autoHeight"
        autoSizePadding={20}
      />
    </div>
  );
};

export default SearchEmplyoeeTable;
