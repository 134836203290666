import React, { useEffect, useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import SearchInputOnly from "components/UserInputs/SearchInputOnly";
// import DownloadDropdown from "components/Dropdowns/DownloadData";
// import SkillsList from "components/SkillsList/SkillsList";

import SkillsTable from "components/Grid/SkillsTable";
import { PiCertificate, PiStar } from "react-icons/pi";
import { MdEdit, MdDelete } from "react-icons/md";
import {
  fetchEmpSpecificSkills,
  fetchAllSkills,
  deleteSkillDetailsAction,
} from "../../store/authSlice";
import { AiOutlineProfile } from "react-icons/ai";
import { connect } from "react-redux";
import ToastComponent from "../../components/Toast/ToastComponent";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "components/Buttons/Button";

const MySkills: React.FC<{
  dispatch: any;
  userDetails?: any;
  emplyoeeSpecificSkills: any;
  click?: () => void;
  updateclick?: (obj: any) => void;
  setLoading: (loading: boolean) => void;
}> = ({
  dispatch,
  userDetails,
  emplyoeeSpecificSkills,
  click,
  updateclick,
}) => {
  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [originalSkillsData, setOriginalSkillsData] = useState([]);

  useEffect(() => {
    if (userDetails && userDetails.data) {
      dispatch(fetchEmpSpecificSkills({ user: userDetails.data.employee }))
        .then((response: any) => {
          // console.log(response);
        })
        .catch((error: any) => {
          console.log(error);
        });

      dispatch(fetchAllSkills())
        .then((response: any) => {
          // console.log(response);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [dispatch, userDetails]);

  const downloadCertificate = (e, url) => {
    e.preventDefault();
    try {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(
        "An error occurred while downloading the PDF/Image:",
        error
      );
    }
  };

  const renderCompetancyWithIcon = (item: any) => {
    return (
      <div className="flex justify-center items-center">
        <span className="font-bold">{item.data.competency}</span>
        <PiStar className="ml-1" />
      </div>
    );
  };

  const renderCertificatesIcon = (item: any) => {
    return (
      <div className="flex justify-center items-center">
        {item.data.employee_certifications.length > 0 ? (
          <>
            {item.data.employee_certifications.map((eci: any) => (
              <span className="ml-2 mt-2">
                <PiCertificate
                  onClick={(e) => downloadCertificate(e, eci.certification_url)}
                  className="ml-2  cursor-pointer"
                  size={"18px"}
                />
              </span>
            ))}
          </>
        ) : (
          "-"
        )}
      </div>
    );
  };

  const renderActionsIcon = (item: any) => {
    return (
      <div className="flex items-center mt-2">
        <MdEdit
          className="ml-2 cursor-pointer"
          onClick={() => updateclick(item.data.allData)}
          size={"18px"}
        />
        <MdDelete
          className="ml-2 cursor-pointer"
          onClick={() => deleteSkillByID(item.data.allData.id)}
          size={"18px"}
        />
      </div>
    );
  };

  useEffect(() => {
    if (
      emplyoeeSpecificSkills &&
      emplyoeeSpecificSkills.data &&
      emplyoeeSpecificSkills.data.data
    ) {
      // console.log(emplyoeeSpecificSkills.data.data);
      const rowCellData = emplyoeeSpecificSkills.data.data.map((item) => ({
        category: item.skills.category || "-",
        skill: item.skills.skill || "-",
        competency: item.competency || "-",
        relevantexp: item.relevantexp || "-",
        employee_certifications: item.employee_certifications || [],
        allData: item,
      }));
      setTableHeader(rowCellData);
      setTableData([
        {
          headerName: "Category",
          field: "category",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Skillset",
          field: "skill",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Competancy",
          field: "competency",
          autoHeight: true,
          wrapText: true,
          cellRenderer: (props) => {
            return renderCompetancyWithIcon(props);
          },
        },
        {
          headerName: "Years of Experience",
          field: "relevantexp",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Certifications",
          field: "employee_certifications",
          autoHeight: true,
          wrapText: true,
          sortable: false,
          cellRenderer: (props) => {
            return renderCertificatesIcon(props);
          },
        },
        {
          headerName: "Actions",
          field: "employee_certifications",
          autoHeight: true,
          wrapText: true,
          sortable: false,
          width: "140px",
          cellRenderer: (props) => {
            return renderActionsIcon(props);
          },
        },
      ]);
      setSkillsData(emplyoeeSpecificSkills.data.data);
      setOriginalSkillsData(emplyoeeSpecificSkills.data.data);
    }
  }, [emplyoeeSpecificSkills]);

  const handleSearch = (searchTerm: string) => {
    if (searchTerm === "") {
      const rowCellData = originalSkillsData.map((item) => ({
        category: item.skills.category || "-",
        skill: item.skills.skill || "-",
        competency: item.competency || "-",
        relevantexp: item.relevantexp || "-",
        employee_certifications: item.employee_certifications || [],
        allData: item,
      }));
      setTableHeader(rowCellData);
      return;
    }
    const filteredData = originalSkillsData.filter((item) => {
      return item.skills.skill.toLowerCase().includes(searchTerm.toLowerCase());
    });

    if (filteredData.length > 0) {
      const rowCellData = filteredData.map((item) => ({
        category: item.skills.category || "-",
        skill: item.skills.skill || "-",
        competency: item.competency || "-",
        relevantexp: item.relevantexp || "-",
        employee_certifications: item.employee_certifications || [],
        allData: item,
      }));
      setTableHeader(rowCellData);
    } else {
      setTableHeader([]);
    }
  };

  function deleteSkillByID(skillId: any) {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this skill?",
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteSkillDetailsAction({ skillId: skillId }))
              .then((response: any) => {
                if (response.error && response.error.message) {
                  ToastComponent.error(response.error.message);
                } else {
                  ToastComponent.success("Deleted Successfully");
                  setTimeout(() => {
                    dispatch(
                      fetchEmpSpecificSkills({
                        user: userDetails.data.employee,
                      })
                    );
                  }, 500);
                }
              })
              .catch((error: any) => {
                console.log(error);
                ToastComponent.error("Delete Failed");
              });
          },
        },
      ],
    });
  }

  return (
    <>
      <div className="ml-auto h-screen">
        <div className="flex flex-row justify-between items-center mx-auto w-full">
          <PageTitle
            title={"MY SKILLS"}
            preTitleIcon={<AiOutlineProfile fontSize={20} />}
          />
          <div>
            <Button
              backgroundColor="black"
              text="Add New"
              onClick={() => click()}
            />
          </div>
        </div>
        {originalSkillsData.length > 0 && (
          <div className="flex flex-row justify-between items-center mx-auto w-full m-4">
            <div style={{ width: "30%" }}>
              <SearchInputOnly
                placeholder="Search..."
                onSearch={handleSearch}
              />
            </div>
            <div>{/* < /> */}</div>
          </div>
        )}

        <div className="w-full mt-2">
          <SkillsTable rowData={tableHeader} colDefs={tableData} />
          {/* <SkillsList
            data={skillsData}
            deleteSkill={(id) => deleteSkillByID(id)}
            updateSkill={(obj) => updateclick(obj)}
          /> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.auth.userDetials,
    emplyoeeSpecificSkills: state.auth.emplyoeeSpecificSkills,
  };
};

export default connect(mapStateToProps)(MySkills);
function setLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
