import React, { useState, useEffect } from "react";

interface Props {
  label: string;
  id: string;
  onChange: (file: File, index: any) => void;
  initialFileUrl?: string;
  index: number;
}

function CertificateUploadWithPreview({
  label,
  id,
  onChange,
  initialFileUrl,
  index,
}: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState("");

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      setSelectedFile(file);
      setFileUrl(URL.createObjectURL(file));
      if (onChange) {
        onChange(file, index); // Pass both file and index to onChange
      }
    }
  };

  const handleClick = () => {
    // Programmatically click the hidden file input
    const fileInput = document.getElementById(id) as HTMLInputElement;
    fileInput && fileInput.click();
  };

  const handleRemove = () => {
    setSelectedFile(null);
    setFileUrl("");
    if (onChange) {
      onChange(null, index); // Pass null for file when removing
    }
  };

  useEffect(() => {
    if (initialFileUrl) {
      const parts = initialFileUrl.split("_");
      if (parts.length > 0) {
        const initFileUrl = parts.splice(0, 1).join("_");
        setFileUrl(initFileUrl);
      } else {
        setFileUrl("");
      }
    }
  }, [initialFileUrl]);

  return (
    <div className="mb-4">
      <label htmlFor={id} className="block text-sm font-semibold text-gray-700">
        {label}
      </label>
      <div
        className="mt-2 flex items-center border-2"
        style={{
          width: "100%",
          height: "42px",
          background: "#f8f9f5",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <div className="flex-grow mr-2 flex items-center justify-center">
          {selectedFile || fileUrl ? (
            <div className="flex flex-col items-center justify-between w-full">
              <span className="text-sm font-medium text-gray-700">
                {selectedFile
                  ? selectedFile.name
                  : initialFileUrl
                  ? initialFileUrl
                      .split("/")
                      .pop()
                      .split("_")
                      .slice(1)
                      .join("_")
                  : ""}
              </span>
              <button
                className="text-red-600 hover:text-red-800 focus:outline-none"
                onClick={handleRemove}
              >
                Remove
              </button>
            </div>
          ) : (
            <span className="text-sm font-medium text-gray-700">
              Choose a file
            </span>
          )}
          <input
            type="file"
            id={id}
            onChange={(e) => handleFileChange(e, index)}
            accept=".pdf, image/*"
            className="sr-only"
          />
        </div>
      </div>
    </div>
  );
}

export default CertificateUploadWithPreview;
