import { toast } from "react-hot-toast";

const Toast = {
  success: (message: any) => {
    toast.success(message, {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
        width: "240px",
      },
    });
  },
  error: (message: any) => {
    toast.error(message, {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
        // width: "240px",
      },
    });
  },
};

export default Toast;
