interface ApiConfig {
  baseUrl: string;
}

const apiConfig: ApiConfig = {
  baseUrl: "https://talentgrid.elfonze.com/apis/public/api",
  // baseUrl: "https://lrvlogin.kilter.fun/public/api",
};

export const MICROSOFT_LOGIN = `${apiConfig.baseUrl}/login/microsoft`;
export const LOGIN_URL = `${apiConfig.baseUrl}/login`;
export const SAVE_EMPLOYEE_DETAILS = `${apiConfig.baseUrl}/employee/store`;
export const UPDATE_EMPLOYEE_DETAILS = `${apiConfig.baseUrl}/employee/update`;
export const EMPLOYEE_LOCATIONS = `${apiConfig.baseUrl}/locations`;
export const GET_USER_DETAILS = `${apiConfig.baseUrl}/userdetails`;
export const GET_EMPLOYEE_DETAILS = `${apiConfig.baseUrl}/employee/show`;
export const GET_MY_SKILLS = `${apiConfig.baseUrl}/my-skills`;

//Employee Skills
export const GET_EMPLOYEE_SKILLS = `${apiConfig.baseUrl}/employee/skills`;
export const GET_ALLSKILLS = `${apiConfig.baseUrl}/all/skills/all`;
export const GET_ALLSKILLSCATEGORY = `${apiConfig.baseUrl}/all/skillsCategory`;
export const GET_EMPLOYEE_SKILLS_WITHFILTER = `${apiConfig.baseUrl}/employee-skill/list`;
export const GET_ALLSKILLS_BYPARAMS = `${apiConfig.baseUrl}/employee/list`;
export const SAVE_SKILL_DETAILS = `${apiConfig.baseUrl}/employee-skill/store`;
export const DELETE_SKILL_DETAILS = `${apiConfig.baseUrl}/employee-skill/delete`;
export const UPDATE_SKILL_DETAILS = `${apiConfig.baseUrl}/employee-skill/update`;
