import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AiOutlineDownload } from "react-icons/ai";
import PopUpModal from "components/Modal/Modal";
import SearchEmplyoeeTable from "../Grid/SearchEmplyoeeTable";
import { PiStar } from "react-icons/pi";

const AllSkillsList = ({ allSkillsByParams }) => {
  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [popupTableHeader, setPopupTableHeader] = useState([]);
  const [popuptableData, setPopupTableData] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const convertValtoString = (arr) => {
    if (!arr || arr.length === 0) {
      return "-";
    }
    return arr
      .map((item) =>
        typeof item === "object" ? `${item.skill} (${item.competency})` : item
      )
      .join(", ");
  };
  const convertValtoString1 = (arr) => {
    if (!arr || arr.length === 0) {
      return "-";
    }

    const uniqueValues = new Set();

    return arr
      .map((item) => {
        if (typeof item === "object") {
          // For objects, check if the category is not already added
          if (!uniqueValues.has(item.category)) {
            uniqueValues.add(item.category);
            return item.category;
          }
        } else {
          // For non-objects, check if the item is not already added
          if (!uniqueValues.has(item)) {
            uniqueValues.add(item);
            return item;
          }
        }
        return null; // Return null for duplicate values
      })
      .filter((item) => item !== null) // Filter out null values (duplicates)
      .join(", ");
  };

  useEffect(() => {
    const renderDownloadIcon = (item) => {
      return (
        <div className="flex justify-center items-center mt-2">
          <a href="/" onClick={(e) => downloadPDF(e, item.value)}>
            <AiOutlineDownload size={"20px"} />
          </a>
        </div>
      );
    };

    const renderNameWithLink = (item: any) => {
      return (
        <div className="flex items-center mt-2">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              showEmployeeModal(item.data.allData);
            }}
            style={{ color: "blue", opacity: "0.7" }}
          >
            {item.value}
          </a>
        </div>
      );
    };
    if (allSkillsByParams?.data) {
      const rowCellData = allSkillsByParams.data.data.map((item) => ({
        empId: item.employee_code || "-",
        empName: item.full_name || "-",
        category: convertValtoString1(item.employee_skills_id || []),
        skills: convertValtoString(item.employee_skills_id || []),
        empEmail: (item.user_obj && item.user_obj.email) || "-",
        relevantExperience: item.relevantexp || "-",
        totalExperience: item.totalexp || "-",
        location: item.location || "-",
        resume: item.resume_url || "-",
        allData: item,
      }));

      setTableHeader(rowCellData);
      setTableData([
        {
          headerName: "Emplyoee Code",
          field: "empId",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Name",
          field: "empName",
          autoHeight: true,
          wrapText: true,
          cellRenderer: (props) => {
            // put the value in bold
            return renderNameWithLink(props);
          },
        },
        {
          headerName: "Category",
          field: "category",
          autoHeight: true,
          wrapText: true,
          width: "300px",
        },
        {
          headerName: "Skills (competency)",
          field: "skills",
          autoHeight: true,
          wrapText: true,
          width: "300px",
        },
        {
          headerName: "Email",
          field: "empEmail",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Location",
          field: "location",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Relevant Experience",
          field: "relevantExperience",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Total Experience",
          field: "totalExperience",
          autoHeight: true,
          wrapText: true,
        },
        {
          headerName: "Resume",
          field: "resume",
          sortable: false,
          cellRenderer: (props) => {
            // put the value in bold
            return renderDownloadIcon(props);
          },
        },
      ]);
    }
  }, [allSkillsByParams]);

  const showEmployeeModal = (data) => {
    console.log(data);
    const renderCompetancyWithIcon = (item: any) => {
      return (
        <div className="flex items-center">
          <span className="font-bold">{item.data.competency}</span>
          <PiStar className="ml-1" />
        </div>
      );
    };
    const rowCellData = data.employee_skills_id.map((item) => ({
      category: item.category || "-",
      skill: item.skill || "-",
      relevantExperience: item.relevantexp || "-",
      competency: item.competency || "-",
      allData: item,
    }));

    setPopupTableHeader(rowCellData);
    setPopupTableData([
      {
        headerName: "Category",
        field: "category",
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "Skill",
        field: "skill",
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "Relevant Experience",
        field: "relevantExperience",
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "Competency",
        field: "competency",
        autoHeight: true,
        wrapText: true,
        cellRenderer: (props) => {
          return renderCompetancyWithIcon(props);
        },
      },
    ]);
    setPopupTitle(`${data?.full_name} (${data?.employee_code})`);
    setTimeout(() => {
      setIsOpen(true);
    }, 100);
  };

  const downloadPDF = (e, url) => {
    e.preventDefault();
    try {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = url.substring(url.lastIndexOf("/") + 1);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("An error occurred while downloading the PDF:", error);
    }
  };

  const footerButtons = [
    {
      label: "Close",
      className:
        "text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150",
      onClick: () => setIsOpen(false),
    },
  ];

  return (
    <div className="w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden rounded-md">
            <p className="mb-2">Employee list</p>
            <SearchEmplyoeeTable rowData={tableHeader} colDefs={tableData} />
            <PopUpModal
              showModal={isOpen}
              setShowModal={setIsOpen}
              title={`Emplyoee Details - ${popupTitle}`}
              body={
                <SearchEmplyoeeTable
                  rowData={popupTableHeader}
                  colDefs={popuptableData}
                  height="30rem"
                />
              }
              footerButtons={footerButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allSkillsByParams: state.auth.allSkillsByParams,
});

export default connect(mapStateToProps)(AllSkillsList);
