import React, { useState, useEffect } from "react";
import EmpDetailsTabs from "../../components/Tabs/EmpDetailsTabs";
import AllEmplyoeeSkills from "views/admin/AllEmplyoeeSkills";
import { connect } from "react-redux";
import {
  getUserDetailsAction,
  fetchAllSkills,
  fetchAllSkillsCategory,
  fetchAllSkillsByparamsAction,
  fetchAllSkillsRecords,
} from "../../store/authSlice";
import "../../assets/styles/common.css";
import { Redirect } from "react-router";

const SearchEmployee: React.FC<{
  dispatch: any;
  userDetails?: any;
  emplyoeeDetails?: any;
}> = ({ dispatch, userDetails, emplyoeeDetails }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  useEffect(() => {
    if (dispatch) {
      dispatch(getUserDetailsAction());
      dispatch(fetchAllSkills());
      dispatch(fetchAllSkillsCategory());
      dispatch(fetchAllSkillsByparamsAction({ paramsURL: "" }));
      dispatch(fetchAllSkillsRecords());
    }
  }, [dispatch]);

  const [showSearchEmployeeItem, setShowSearchEmployeeItem] = useState(true);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.data &&
      userDetails.data.roles.length > 0 &&
      userDetails.data.roles.some((role: any) => role.role_name === "manager")
    ) {
      setShowSearchEmployeeItem(true);
    } else {
      setShowSearchEmployeeItem(false);
    }
  }, [dispatch, userDetails]);

  const handleSkillsDisplay = () => {
    return <AllEmplyoeeSkills />;
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const tabs = [{ title: "Search Profiles", content: handleSkillsDisplay() }];

  return (
      <div className="mt-20 py-4 px-8 w-3/4 ml-auto h-screen">
      {showSearchEmployeeItem ?
        <div className="px-4 md:px-10 mx-auto w-full">
          <EmpDetailsTabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
        </div> : <Redirect to="/" />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.auth.userDetials,
    emplyoeeDetails: state.auth.emplyoeeDetails,
  };
};

export default connect(mapStateToProps)(SearchEmployee);
