import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import { userLogin } from "./utils/api";
import { setToken } from "store/authSlice";
import LoginPage from "views/auth/Login";
import DashboardPage from "views/admin/Dashboard";
import EmployeeDetailsPage from "views/common/EmployeeDetails";
import SearchEmployeePage from "views/common/SearchEmployee";
import AuthLayout from "layouts/AuthLayout";
import Layout from "layouts/Layout";
import { RootState } from "./store/store";
import { Toaster } from "react-hot-toast";
import './utils/interceptor';

interface AppProps {
  dispatch: any;
  isLoggedIn: boolean;

}

const App: React.FC<AppProps> = ({ dispatch, isLoggedIn, }) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      dispatch(setToken(token));
    }
  }, [dispatch]);

  const handleLogin = async (email: string, password: string) => {
    try {
      setLoading(true);
      const response = await userLogin(email, password);
      dispatch(setToken(response.token));
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    dispatch(setToken(null));
  };

  return (
    <>
      <Router>
        <Switch>
          <Route path="/login" exact>
            <AuthLayout onLogout={handleLogout} isLoggedIn={isLoggedIn}>
              {isLoggedIn ? (
                <Redirect to="/employee-details" />
              ) : (
                <LoginPage
                  onLogin={(email, password) => handleLogin(email, password)}
                  loading={loading}
                  microsoftLogin={() => dispatch(setToken("mockToken"))}
                />
              )}
            </AuthLayout>
          </Route>
          <Route path="/">
            <Layout onLogout={handleLogout} isLoggedIn={isLoggedIn} >
              <Switch>
                <Route path="/dashboard" exact>
                  {isLoggedIn ? <DashboardPage /> : <Redirect to="/login" />}
                </Route>
                <Route path="/employee-details" exact>
                  {isLoggedIn ? (
                    <EmployeeDetailsPage />
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Route>
                <Route path="/search-employee" exact>
                  {isLoggedIn ? (
                    <SearchEmployeePage />
                  ) : (
                    <Redirect to="/login" />
                  )}
                </Route>
                <Redirect to="/employee-details" />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
      <Toaster />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.auth.token !== null,
  };
};

export default connect(mapStateToProps)(App);
